import i18next from 'i18next';
import React from 'react';

import { EIconColor } from './Icon.enums';
import { TIconProps } from './Icon.types';

const Svg: React.FC<TIconProps> = ({ color = EIconColor.BLACK }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.46875 18.75L15.0469 18.75C15.209 18.75 15.3637 18.685 15.4783 18.5708L16.2656 17.7768L17.0529 18.5708C17.1675 18.685 17.3223 18.75 17.4844 18.75L20.5312 18.75C21.2028 18.75 21.75 18.199 21.75 17.5227L21.75 6.47727C21.75 5.79982 21.2028 5.25 20.5313 5.25L17.4844 5.25C17.3223 5.25 17.1675 5.31504 17.0529 5.42918L16.2656 6.22323L15.4783 5.43041C15.3638 5.31504 15.209 5.25 15.0469 5.25L3.46875 5.25C2.796 5.25 2.25 5.79982 2.25 6.47727L2.25 17.5227C2.25 18.199 2.796 18.75 3.46875 18.75Z"
        fill={color}
      />
      <path
        d="M11.3868 12.6135C12.4017 12.6135 13.2277 13.4394 13.2277 14.4544C13.2277 15.4693 12.4017 16.2953 11.3868 16.2953C10.3718 16.2953 9.54584 15.4693 9.54584 14.4544C9.54584 13.4394 10.3718 12.6135 11.3868 12.6135ZM11.3868 15.068C11.7255 15.068 12.0004 14.7931 12.0004 14.4544C12.0004 14.1157 11.7255 13.8407 11.3868 13.8407C11.048 13.8407 10.7731 14.1157 10.7731 14.4544C10.7731 14.7931 11.048 15.068 11.3868 15.068Z"
        fill="white"
      />
      <path
        d="M7.70487 7.70441C8.71982 7.70441 9.54578 8.53037 9.54578 9.54532C9.54578 10.5603 8.71982 11.3862 7.70487 11.3862C6.68991 11.3862 5.86396 10.5603 5.86396 9.54532C5.86396 8.53037 6.68991 7.70441 7.70487 7.70441ZM7.70487 10.159C8.04237 10.159 8.3185 9.88282 8.3185 9.54532C8.3185 9.20782 8.04237 8.93169 7.70487 8.93169C7.36737 8.93169 7.09123 9.20782 7.09123 9.54532C7.09123 9.88282 7.36737 10.159 7.70487 10.159Z"
        fill="white"
      />
      <path
        d="M12.6141 7.70444C12.7528 7.70444 12.8914 7.75107 13.0068 7.8468C13.267 8.06403 13.3026 8.45062 13.0854 8.7108L6.94899 16.0744C6.73053 16.3358 6.34394 16.3678 6.08499 16.153C5.82481 15.9358 5.79044 15.5492 6.00767 15.2878L12.144 7.92412C12.2643 7.78053 12.4386 7.70444 12.6141 7.70444Z"
        fill="white"
      />
      <path d="M15.6822 16.2953L16.9094 16.2953L16.9094 15.068L15.6822 15.068L15.6822 16.2953Z" fill="white" />
      <path d="M15.6822 8.93164L16.9094 8.93164L16.9094 7.70437L15.6822 7.70437L15.6822 8.93164Z" fill="white" />
      <path d="M15.6822 11.3862L16.9094 11.3862L16.9094 10.159L15.6822 10.159L15.6822 11.3862Z" fill="white" />
      <path d="M15.6822 13.8407L16.9094 13.8407L16.9094 12.6134L15.6822 12.6134L15.6822 13.8407Z" fill="white" />
    </svg>
  );
};

export default Svg;
