import i18next from 'i18next';
import React from 'react';

import { TIconProps } from './Icon.types';

const Svg: React.FC<TIconProps> = () => {
  return (
    <svg width="65" height="66" viewBox="0 0 65 66" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M32.5 0.5C41.1195 0.5 49.386 3.9241 55.481 10.019C61.5759 16.114 65 24.3805 65 33C65 41.6195 61.5759 49.886 55.481 55.981C49.386 62.0759 41.1195 65.5 32.5 65.5C23.8805 65.5 15.614 62.0759 9.51903 55.981C3.4241 49.886 0 41.6195 0 33C0 24.3805 3.4241 16.114 9.51903 10.019C15.614 3.9241 23.8805 0.5 32.5 0.5ZM32.5 60.8571C39.8882 60.8571 46.9737 57.9222 52.198 52.698C57.4222 47.4737 60.3571 40.3882 60.3571 33C60.3571 25.6118 57.4222 18.5263 52.198 13.302C46.9737 8.0778 39.8882 5.14286 32.5 5.14286C25.1118 5.14286 18.0263 8.0778 12.802 13.302C7.5778 18.5263 4.64286 25.6118 4.64286 33C4.64286 40.3882 7.5778 47.4737 12.802 52.698C18.0263 57.9222 25.1118 60.8571 32.5 60.8571ZM35.9821 48.0893C35.9821 49.0128 35.6153 49.8985 34.9622 50.5515C34.3092 51.2046 33.4235 51.5714 32.5 51.5714C31.5765 51.5714 30.6908 51.2046 30.0378 50.5515C29.3847 49.8985 29.0179 49.0128 29.0179 48.0893C29.0179 47.1658 29.3847 46.2801 30.0378 45.627C30.6908 44.974 31.5765 44.6071 32.5 44.6071C33.4235 44.6071 34.3092 44.974 34.9622 45.627C35.6153 46.2801 35.9821 47.1658 35.9821 48.0893ZM32.5 14.4286C33.1157 14.4286 33.7061 14.6732 34.1415 15.1085C34.5769 15.5439 34.8214 16.1343 34.8214 16.75V37.6429C34.8214 38.2585 34.5769 38.849 34.1415 39.2844C33.7061 39.7197 33.1157 39.9643 32.5 39.9643C31.8843 39.9643 31.2939 39.7197 30.8585 39.2844C30.4231 38.849 30.1786 38.2585 30.1786 37.6429V16.75C30.1786 16.1343 30.4231 15.5439 30.8585 15.1085C31.2939 14.6732 31.8843 14.4286 32.5 14.4286Z"
        fill="#F39400"
      />
    </svg>
  );
};

export default Svg;
