import classNames from 'classnames';
import i18next from 'i18next';
import React, { useEffect, useRef } from 'react';

import './Stack.scss';
import { TStackProps } from './Stack.types.d';

const Stack: React.FC<TStackProps> = (props) => {
  const { id, children, className, style, space = 'var(--s1)', recursive = false, splitAfter, ...rest } = props;

  const prefixCls = classNames('stack');
  const classes = classNames(prefixCls, className);
  const stackStyle = {
    ...(style || {}),
  };

  const elementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const render = (): void => {
      if (elementRef.current) {
        const i = `Stack-${[space, recursive, splitAfter].join('')}`;
        elementRef.current.dataset.i = i;

        if (!document.getElementById(i)) {
          const styleEl = document.createElement('style');
          styleEl.id = i;
          styleEl.innerHTML = `
            [data-i="${i}"]${recursive ? '' : ' >'} * + * {
              margin-block-start: ${space};
            }

            ${
              splitAfter
                ? `
              [data-i="${i}"]:only-child {
                block-size: 100%;
              }

              [data-i="${i}"] > :nth-child(${splitAfter}) {
                margin-block-end: auto;
              }`
                : ''
            }
          `
            .replace(/\s\s+/g, ' ')
            .trim();
          document.head.appendChild(styleEl);
        }
      }
    };
    render();
  }, [space, recursive, splitAfter]);

  return (
    <div {...rest} ref={elementRef} id={id} style={stackStyle} className={classes}>
      {children}
    </div>
  );
};

export default Stack;
