export enum EIconName {
  Location = 'Location',
  AngleDown = 'AngleDown',
  AngleLeft = 'AngleLeft',
  AngleRight = 'AngleRight',
  Search = 'Search',
  Calendar = 'Calendar',
  Filter = 'Filter',
  Facebook = 'Facebook',
  Instagram = 'Instagram',
  Youtube = 'Youtube',
  Verify = 'Verify',
  StarFill = 'StarFill',
  X = 'X',
  EyeClosed = 'EyeClosed',
  Eye = 'Eye',
  Lightning = 'Lightning',
  Heart = 'Heart',
  Chat = 'Chat',
  House = 'House',
  CaretDown = 'CaretDown',
  Photo = 'Photo',
  Minus = 'Minus',
  Plus = 'Plus',
  MakeUp = 'MakeUp',
  Lamp = 'Lamp',
  Scissors = 'Scissors',
  Spa = 'Spa',
  Check = 'Check',
  Users = 'Users',
  PlusSquare = 'PlusSquare',
  Voucher = 'Voucher',
  GuCoin = 'GuCoin',
  Note = 'Note',
  CheckCircle = 'CheckCircle',
  PencilEdit = 'PencilEdit',
  Setting = 'Setting',
  Gps = 'Gps',
  Trash = 'Trash',
  LocationFill = 'LocationFill',
  Menu = 'Menu',
  Bell = 'Bell',
  Calendar2 = 'Calendar2',
  ChatCircle = 'ChatCircle',
  User = 'User',
  HeartOutline = 'HeartOutline',
  VoucherMobile = 'VoucherMobile',
  GuCoinCircle = 'GuCoinCircle',
  FullArrowLeft = 'FullArrowLeft',
  Warning = 'Warning',
  Clock = 'Clock',
  MyGu = 'MyGu',
}

export enum EIconColor {
  BLACK = '#000',
  WHITE = '#fff',

  PRIMARY = '#1E69CB',
  SECONDARY = '#F6CCD5',

  ALUMINIUM = '#ACB3BD',
  MINE_SHAFT = '#333333',
  GRAY_CHATEAU = '#A0A2A3',
  REGENT_GRAY = '#8492A6',
  POMEGRANATE = '#F62B22',
  HEATHER = '#C2CEDB',
  DOVE_GRAY = '#676767',
  MIRAGE = '#1c2433',
  HAVELOCK_BLUE = '#438BD3',
  MOUNTAIN_MEADOW = '#27D354',
}
