import ApiService from '@/services/api';

// TYPES

export type TGetMyPointParams = unknown;

export type TGetMyPointMaterials = {
  params?: TGetMyPointParams;
};

export type TGetMyPointResponse = {
  data: number;
};

// FUNCTION

export const getMyPoint = async ({ params }: TGetMyPointMaterials): Promise<TGetMyPointResponse> => {
  const response = await ApiService.get(`/user/my-point`, { params });
  return response?.data;
};
