import { List, Skeleton, Typography } from 'antd';
import classNames from 'classnames';
import i18next from 'i18next';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';

import Button, { EButtonStyleType } from '@/components/Button';
import Icon, { EIconColor, EIconName } from '@/components/Icon';
import './GuPoint.scss';
import { TGuPointProps } from './GuPoint.types.d';

import { THistoryPoint, TPaging } from '@/common/models';
import Tabs, { ETabsStyleType } from '@/components/Tabs';
import { getHistoryPointAction, getMyPointAction } from '@/redux/actions';
import { TRootState } from '@/redux/reducers';
import { isZaloApp } from '@/utils/functions';

const GuPoint: React.FC<TGuPointProps> = ({ onBack }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [historyState, setHistoryState] = useState<{ type: string; data: THistoryPoint[]; paging: TPaging }>({
    type: '',
    data: [],
    paging: {
      page: 0,
      pageCount: 0,
      total: 0,
    },
  });

  const myPointState = useSelector((state: TRootState) => state.loyaltyReducer.getMyPointResponse)?.data;

  const prefixCls = 'GuPoint';

  const handleBackClick = (): void => {
    onBack?.();
  };

  const getMyPoints = useCallback(() => {
    dispatch(
      getMyPointAction.request({
        params: null,
      }),
    );
  }, [dispatch]);

  const getHistoryPoints = useCallback(
    (listType: string, page: number) => {
      const needGetData =
        (listType === historyState.type && page !== historyState.paging.page) || listType !== historyState.type;

      needGetData &&
        dispatch(
          getHistoryPointAction.request(
            {
              params: {
                type: listType,
                page,
                limit: 10,
              },
            },
            (res) => {
              if (res && Array.isArray(res?.data) && res.paging) {
                setHistoryState({
                  type: listType,
                  data: historyState.type !== listType ? res?.data : [...historyState.data, ...res?.data],
                  paging: res.paging,
                });
              }
              setLoading(false);
            },
            () => {
              setLoading(false);
            },
          ),
        );
    },
    [historyState, dispatch],
  );

  const loadMoreData = useCallback((): void => {
    if (loading) {
      return;
    }
    setLoading(true);
    setTimeout(() => {
      getHistoryPoints(historyState.type, historyState.paging.page + 1);
    }, 500);
  }, [getHistoryPoints, historyState, loading]);

  useEffect(() => {
    if (historyState.paging.page === 0 && historyState.type === '') {
      getMyPoints();
      getHistoryPoints('ALL', 1);
    }
  }, [historyState, getMyPoints, getHistoryPoints]);

  const renderData = useCallback(
    (data: THistoryPoint[], hasMore: boolean): ReactElement => {
      return (
        <div
          id="scrollableDiv"
          style={{
            height: '60vh',
            overflow: 'auto',
          }}
        >
          <InfiniteScroll
            dataLength={data.length}
            next={loadMoreData}
            hasMore={hasMore}
            loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
            scrollableTarget="scrollableDiv"
          >
            <List
              dataSource={data}
              renderItem={(item): ReactElement => (
                <List.Item key={item.id}>
                  <List.Item.Meta
                    avatar={
                      <Icon
                        name={EIconName.GuCoinCircle}
                        style={{
                          width: '40px',
                          marginInlineEnd: '2rem',
                        }}
                      />
                    }
                    title={
                      <span>
                        {item.action === 'ATTENDANCE_DAILY'
                          ? i18next.t('Điểm danh mỗi ngày')
                          : i18next.t('Hoàn thành sử dụng dịch vụ')}
                      </span>
                    }
                    description={item.created_at}
                  />
                  <span className={`${classNames(prefixCls)}-line-suffix`}>+{item.point.toLocaleString()}</span>
                </List.Item>
              )}
            />
          </InfiniteScroll>
        </div>
      );
    },
    [loadMoreData],
  );

  return (
    <div className={classNames(prefixCls, { 'mini-app': isZaloApp() })}>
      <div className={classNames(`${prefixCls}-wrapper`)}>
        {isZaloApp() && (
          <div className={classNames(`${prefixCls}-header`)}>
            <div className={classNames(`${prefixCls}-nav`)}>
              {onBack && (
                <span className={classNames(`${prefixCls}-nav-back`)}>
                  <Button
                    styleType={EButtonStyleType.TRANSPARENT}
                    iconColor={EIconColor.WHITE}
                    onClick={handleBackClick}
                    iconName={EIconName.FullArrowLeft}
                  />
                </span>
              )}
              <div className={classNames(`${prefixCls}-nav-title`)}>
                <h3>{i18next.t('Điểm Gu')}</h3>
              </div>
            </div>
          </div>
        )}
        <div className={classNames(`${prefixCls}-main`)}>
          <div className={classNames(`${prefixCls}-card`)}>
            <span className={classNames(`${prefixCls}-card-title`)}>{i18next.t('Tổng Số Gu')}</span>
            <div className={classNames(`${prefixCls}-card-body`)}>
              <Typography.Title className="m-0" level={4}>
                {myPointState?.toLocaleString()}
              </Typography.Title>
              <Icon name={EIconName.GuCoinCircle} />
            </div>
          </div>
          <Tabs
            className={classNames(`${prefixCls}-tabs`)}
            data={[
              {
                key: 'ALL',
                title: i18next.t('Tất Cả'),
                children:
                  historyState.type === 'ALL' ? (
                    renderData(historyState.data, historyState.paging.page < historyState.paging.pageCount)
                  ) : (
                    <></>
                  ),
              },
              {
                key: 'RECEIVED',
                title: i18next.t('Đã nhận'),
                children:
                  historyState.type === 'RECEIVED' ? (
                    renderData(historyState.data, historyState.paging.page < historyState.paging.pageCount)
                  ) : (
                    <></>
                  ),
              },
              {
                key: 'USED',
                title: i18next.t('Đã dùng'),
                children:
                  historyState.type === 'USED' ? (
                    renderData(historyState.data, historyState.paging.page < historyState.paging.pageCount)
                  ) : (
                    <></>
                  ),
              },
            ]}
            styleType={ETabsStyleType.LINE}
            onChange={(activeKey: string): void => {
              getHistoryPoints(activeKey, 1);
            }}
          />
          <Button
            className={classNames(`${prefixCls}-earnPoint`)}
            title={i18next.t('Cách kiếm Gu')}
            size="large"
            styleType={EButtonStyleType.PRIMARY}
          />
        </div>
      </div>
    </div>
  );
};

export default GuPoint;
