// eslint-disable-next-line global-require
import i18next from 'i18next';
import React from 'react';

import classNames from 'classnames';
import { openShareSheet, saveImageToGallery } from 'zmp-sdk/apis';

import ContactAdminIMG from '@/assets/images/contact-admin.png';
import Button, { EButtonStyleType } from '@/components/Button';
import { EIconColor, EIconName } from '@/components/Icon';

import { isZaloApp, showNotification } from '@/utils/functions';
import './ContactAdmin.scss';
import { TContactAdminProps } from './ContactAdmin.types.d';
import { ZALO_MINI_APP_BASE_PATH } from '@/common/constants';
import { ETypeNotification } from '@/common/enums';

const ContactAdmin: React.FC<TContactAdminProps> = ({ onBack }) => {
  const prefixCls = 'ContactAdmin';

  const handleBackClick = (): void => {
    onBack?.();
  };

  return (
    <div className={classNames(prefixCls, { 'mini-app': isZaloApp() })}>
      <div className={classNames(`${prefixCls}-wrapper`)}>
        {isZaloApp() && (
          <div className={classNames(`${prefixCls}-header`)}>
            <div className={classNames(`${prefixCls}-nav`)}>
              {onBack && (
                <span className={classNames(`${prefixCls}-nav-back`)}>
                  <Button
                    styleType={EButtonStyleType.TRANSPARENT}
                    iconColor={EIconColor.WHITE}
                    onClick={handleBackClick}
                    iconName={EIconName.FullArrowLeft}
                  />
                </span>
              )}
              <div className={classNames(`${prefixCls}-nav-title`)}>
                <h3>{i18next.t('Liên Hệ Admin')}</h3>
              </div>
            </div>
          </div>
        )}
        <div className={classNames(`${prefixCls}-main`)}>
          <div className="flex items-center justify-center h-full">
            <img width="250" alt="contact-admin" src={ContactAdminIMG} />
          </div>
          {isZaloApp() && (
            <div className={classNames(`${prefixCls}-btn-group`)}>
              <Button
                className={classNames(`${prefixCls}-earnPoint`)}
                title="Lưu"
                size="large"
                styleType={EButtonStyleType.PRIMARY_OUTLINE}
                onClick={(): void => {
                  saveImageToGallery({
                    imageUrl: `https://h5.zdn.vn${ZALO_MINI_APP_BASE_PATH}/${
                      isZaloApp() && window.APP_VERSION ? window.APP_VERSION + ContactAdminIMG : ContactAdminIMG
                    }`,
                    success: () => {
                      showNotification(ETypeNotification.SUCCESS, i18next.t('Lưu thành công'));
                    },
                    fail: (error) => {
                      showNotification(ETypeNotification.ERROR, i18next.t('Lưu thất bại'));
                    },
                  });
                }}
              />
              <Button
                className={classNames(`${prefixCls}-earnPoint`)}
                title="Chia sẻ"
                size="large"
                styleType={EButtonStyleType.PRIMARY}
                onClick={(): void => {
                  openShareSheet({
                    type: 'image',
                    data: {
                      imageUrl: `https://h5.zdn.vn${ZALO_MINI_APP_BASE_PATH}/${
                        isZaloApp() && window.APP_VERSION ? window.APP_VERSION + ContactAdminIMG : ContactAdminIMG
                      }`,
                    },
                    success: () => {
                      // showNotification(ETypeNotification.SUCCESS, i18next.t('Lưu thành công'));
                    },
                    fail: (error) => {
                      // showNotification(ETypeNotification.ERROR, i18next.t('Lưu thất bại'));
                    },
                  });
                }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContactAdmin;
