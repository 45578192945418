import i18next from 'i18next';
import React from 'react';

import { EIconColor } from './Icon.enums';
import { TIconProps } from './Icon.types';

const Svg: React.FC<TIconProps> = ({ color = EIconColor.BLACK }) => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.62894 12.6775C9.69233 12.8042 9.78741 12.8676 9.91418 12.8676C9.94588 12.8676 9.97757 12.8676 10.041 12.8359C10.1994 12.7725 10.2945 12.5824 10.2311 12.4239L6.45959 3.10602C6.36451 2.91586 6.45959 2.66232 6.61806 2.56724C6.77653 2.47215 6.87161 2.50385 6.935 2.53554C7.09346 2.59893 7.18854 2.66232 7.25193 2.7574L11.4355 9.79335C11.5306 9.95181 11.7207 9.98351 11.8792 9.88843C12.0376 9.79335 12.0693 9.60319 11.9743 9.44472L7.82241 2.44046C7.66395 2.12353 7.3787 1.99675 7.15685 1.93337C6.9033 1.83829 6.61806 1.86998 6.33282 2.02845C5.85742 2.31369 5.66726 2.91586 5.88911 3.35957L9.62894 12.6775Z"
        fill={color}
      />
      <path
        d="M16.7917 17.5262L15.4289 15.2443C15.3338 15.0858 15.1437 15.0541 14.9852 15.1175C14.8267 15.2126 14.795 15.4028 14.8584 15.5612L16.0945 17.6213C15.8092 17.7164 15.5557 17.8432 15.3338 18.0333L14.5098 16.5754C14.4147 16.417 14.2245 16.3853 14.0661 16.4486C13.9076 16.5437 13.8759 16.7339 13.9393 16.8924L14.8584 18.5087C14.6049 18.889 14.4781 19.3328 14.4781 19.8082C14.4781 21.0759 15.524 22.1218 16.7917 22.1218C18.0595 22.1218 19.1053 21.0759 19.1053 19.8082C19.1053 18.5721 18.0595 17.5262 16.7917 17.5262ZM16.7917 21.5196C15.8726 21.5196 15.112 20.759 15.112 19.8398C15.112 19.4912 15.2387 19.1426 15.3972 18.889C15.4606 18.8574 15.4923 18.8257 15.524 18.794C15.6191 18.6672 15.7775 18.5404 15.936 18.4453C16.1578 18.3186 16.3797 18.2235 16.6332 18.1918C16.6649 18.1918 16.6966 18.1918 16.7283 18.1601H16.7917C17.7108 18.1601 18.4715 18.9207 18.4715 19.8398C18.4715 20.759 17.7108 21.5196 16.7917 21.5196Z"
        fill={color}
      />
      <path
        d="M18.6614 2.12331C18.5029 2.02823 18.3445 1.96484 18.1226 1.96484C18.0275 1.96484 17.9642 1.96484 17.8691 1.99654C17.5838 2.05992 17.2986 2.25009 17.1718 2.50363L8.04409 17.4946C6.77635 17.4946 5.73047 18.5405 5.73047 19.8083C5.73047 21.076 6.77635 22.1219 8.04409 22.1219C9.31183 22.1219 10.3577 21.076 10.3577 19.8083C10.3577 19.3329 10.1992 18.8892 9.9457 18.5088L11.4987 15.9417C11.8473 15.3712 12.5129 15.0542 13.2101 15.181C13.7806 15.2761 14.3194 14.9592 14.5413 14.4521L19.1051 3.45444C19.327 2.94734 19.1368 2.37686 18.6614 2.12331ZM8.0124 21.488C7.09329 21.488 6.33264 20.7274 6.33264 19.8083C6.33264 18.8892 7.09329 18.1285 8.0124 18.1285C8.23425 18.1285 8.45611 18.1602 8.64627 18.2553C8.67796 18.2553 8.67796 18.2553 8.70966 18.287C8.77304 18.3187 8.83643 18.3504 8.86812 18.3821C9.05828 18.5088 9.21675 18.6356 9.31183 18.7624C9.31183 18.7624 9.34353 18.7624 9.34353 18.7941C9.56538 19.0793 9.72385 19.4279 9.72385 19.84C9.69215 20.7274 8.93151 21.488 8.0124 21.488ZM18.5346 3.20089L13.9391 14.1985C13.844 14.4204 13.6221 14.5472 13.4003 14.5472C13.3686 14.5472 13.3369 14.5472 13.3052 14.5472C13.1784 14.5155 13.0517 14.5155 12.9249 14.5155C12.1009 14.5155 11.3719 14.9275 10.9599 15.6247L9.4703 18.0334C9.24844 17.875 9.02659 17.7165 8.77304 17.6214C8.74135 17.6214 8.74135 17.5897 8.70966 17.5897L17.7423 2.75718C17.774 2.6621 17.9008 2.59871 17.9958 2.56702H18.1543C18.2177 2.56702 18.2811 2.56702 18.3445 2.59871L18.3762 2.63041C18.5346 2.75718 18.6297 2.97903 18.5346 3.20089Z"
        fill={color}
      />
      <path
        d="M12.8932 12.1387C12.3544 12.1387 11.9424 12.5507 11.9424 13.0895C11.9424 13.6283 12.3544 14.0403 12.8932 14.0403C13.432 14.0403 13.844 13.6283 13.844 13.0895C13.844 12.5507 13.4003 12.1387 12.8932 12.1387ZM12.8932 13.4064C12.703 13.4064 12.5763 13.2479 12.5763 13.0895C12.5763 12.931 12.703 12.7725 12.8932 12.7725C13.0833 12.7725 13.2101 12.931 13.2101 13.0895C13.2101 13.2479 13.0833 13.4064 12.8932 13.4064Z"
        fill={color}
      />
    </svg>
  );
};

export default Svg;
