import { createActionCreator } from 'deox';

import { TGetHistoryPointMaterials, TGetHistoryPointResponse } from '@/services/api/loyalty/get-history-point';

// CONSTANTS

export enum EGetHistoryPointAction {
  GET_HISTORY_POINT = 'GET_HISTORY_POINT',
  GET_HISTORY_POINT_REQUEST = 'GET_HISTORY_POINT_REQUEST',
  GET_HISTORY_POINT_SUCCESS = 'GET_HISTORY_POINT_SUCCESS',
  GET_HISTORY_POINT_FAILED = 'GET_HISTORY_POINT_FAILED',
}

// TYPES

export type TGetHistoryPointRequest = {
  type: EGetHistoryPointAction.GET_HISTORY_POINT_REQUEST;
  payload: {
    materials: TGetHistoryPointMaterials;
    successCallback?: (response: TGetHistoryPointResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetHistoryPointSuccess = {
  type: EGetHistoryPointAction.GET_HISTORY_POINT_SUCCESS;
  payload: { response: TGetHistoryPointResponse };
};

export type TGetHistoryPointFailed = { type: EGetHistoryPointAction.GET_HISTORY_POINT_FAILED };

// FUNCTION

export const getHistoryPointAction = {
  request: createActionCreator(
    EGetHistoryPointAction.GET_HISTORY_POINT_REQUEST,
    (resolve) =>
      (
        materials: TGetHistoryPointMaterials,
        successCallback?: (response: TGetHistoryPointResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetHistoryPointRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetHistoryPointAction.GET_HISTORY_POINT_SUCCESS,
    (resolve) =>
      (response: TGetHistoryPointResponse): TGetHistoryPointSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetHistoryPointAction.GET_HISTORY_POINT_FAILED,
    (resolve) =>
      (error: unknown): TGetHistoryPointFailed =>
        resolve({ error }),
  ),
};
