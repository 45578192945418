import { useLocation } from '@reach/router';
import { Drawer } from 'antd';
import classNames from 'classnames';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { openWebview } from 'zmp-sdk/apis';
import i18next from 'i18next';
import { navigate } from '@/utils/router';

import Avatar from '@/components/Avatar';
import Icon, { EIconColor, EIconName } from '@/components/Icon';
import { LayoutPaths, Paths } from '@/pages/routers';
import { TRootState } from '@/redux/reducers';

import { ZALO_MINI_APP_BASE_PATH } from '@/common/constants';
import { ERankColors, ERankLevels, ERankNames } from '@/common/enums';
import Loyalty from '../Loyalty';
import { dataSidebarAccount, dataSidebarSetting } from './SideBar.data';
import './SideBar.scss';
import { TSideBarProps } from './SideBar.types.d';

import RankIcon1 from '@/assets/icons/rank/icon-rank-1.svg';
import RankIcon2 from '@/assets/icons/rank/icon-rank-2.svg';
import RankIcon3 from '@/assets/icons/rank/icon-rank-3.svg';
import RankIcon4 from '@/assets/icons/rank/icon-rank-4.svg';
import RankIcon5 from '@/assets/icons/rank/icon-rank-5.svg';
import RankIcon6 from '@/assets/icons/rank/icon-rank-6.svg';

import OP from '@/assets/images/OperationRules012024.png';
import RankBG1 from '@/assets/images/rank/bg-1.png';
import RankBG2 from '@/assets/images/rank/bg-2.png';
import RankBG3 from '@/assets/images/rank/bg-3.png';
import RankBG4 from '@/assets/images/rank/bg-4.png';
import RankBG5 from '@/assets/images/rank/bg-5.png';
import RankBG6 from '@/assets/images/rank/bg-6.png';
import { cleanPercentage, isZaloApp } from '@/utils/functions';
import ChangePassword from '../ChangePassword';
import ContactAdmin from '../ContactAdmin';
import GuPoint from '../GuPoint';

const SideBar: React.FC<TSideBarProps> = () => {
  const { pathname } = useLocation();
  const [visibleBottomSheet, setVisibleBottomSheet] = useState(false);
  const [sheetBody, setSheetBody] = useState(<></>);

  const myProfileState = useSelector((state: TRootState) => {
    const data = state.userReducer.getMyProfileResponse?.data;
    const rankConfig = data?.rank_config;
    let rankLabel;
    let rankName;
    let rankIcon;
    let rankBG;
    let rankPercentage;
    let remainingAmountToNextRank;
    let rankColor;
    let discountPercentage;
    if (rankConfig) {
      const rankOptions = Object.keys(rankConfig || {}).map((key) => {
        const dataRank = ((rankConfig || {}) as any)[key];
        return {
          value: dataRank?.value,
          label: key,
          data: dataRank,
        };
      });
      const currentRank = rankOptions.find((item) => Number(item.value) === data?.rank);

      if (currentRank) {
        rankLabel = currentRank.label;
        discountPercentage = currentRank.data?.percent;
        try {
          rankPercentage = cleanPercentage((data.amount_spent / currentRank.data?.max) * 100);
          remainingAmountToNextRank = currentRank.data?.max - data.amount_spent;
        } catch (error) {
          // bypass
        }
        switch (currentRank?.value) {
          case ERankLevels.POTENTIAL:
            rankName = ERankNames.POTENTIAL;
            rankColor = ERankColors.POTENTIAL;
            rankIcon = RankIcon1;
            rankBG = RankBG1;
            break;
          case ERankLevels.BRONZE:
            rankName = ERankNames.BRONZE;
            rankColor = ERankColors.BRONZE;
            rankIcon = RankIcon2;
            rankBG = RankBG2;
            break;
          case ERankLevels.SILVER:
            rankName = ERankNames.SILVER;
            rankColor = ERankColors.SILVER;
            rankIcon = RankIcon3;
            rankBG = RankBG3;
            break;
          case ERankLevels.GOLD:
            rankName = ERankNames.GOLD;
            rankColor = ERankColors.GOLD;
            rankIcon = RankIcon4;
            rankBG = RankBG4;
            break;
          case ERankLevels.PLATINUM:
            rankName = ERankNames.PLATINUM;
            rankColor = ERankColors.PLATINUM;
            rankIcon = RankIcon5;
            rankBG = RankBG5;
            break;
          case ERankLevels.DIAMOND:
            rankName = ERankNames.DIAMOND;
            rankColor = ERankColors.DIAMOND;
            rankIcon = RankIcon6;
            rankBG = RankBG6;
            break;

          default:
            break;
        }
      }
    }
    return {
      name: data?.name,
      avatar: data?.avatar,
      rankLevel: data?.rank,
      rankColor,
      rankLabel,
      rankName,
      rankIcon,
      rankBG,
      rankPercentage,
      remainingAmountToNextRank,
      discountPercentage,
    };
  });

  const menu = (
    <>
      <div className="SideBar-card">
        <div className="SideBar-card-wrapper">
          {dataSidebarAccount.map((item) => (
            <div
              key={item.key}
              className={classNames('SideBar-card-item flex items-center', {
                active: (item?.activePaths as string[])?.includes(pathname),
                disabled: item?.disabled,
              })}
              onClick={(): void => {
                if (isZaloApp() && item.popup) {
                  switch (item.key) {
                    case '2':
                      setSheetBody(<GuPoint onBack={(): void => setVisibleBottomSheet(false)} />);
                      setVisibleBottomSheet(true);
                      break;
                    case '6':
                      setSheetBody(<ChangePassword onBack={(): void => setVisibleBottomSheet(false)} />);
                      setVisibleBottomSheet(true);
                      break;

                    default:
                      break;
                  }
                } else if (item.link && !item.disabled) {
                  navigate(item.link);
                }
              }}
            >
              <div className="SideBar-card-item-icon">
                <img src={item.icon} alt="" />
              </div>
              <div className="SideBar-card-subtitle">{item.title}</div>
              <div className="SideBar-card-item-arrow" style={{ marginLeft: 'auto' }}>
                <Icon name={EIconName.AngleRight} color={EIconColor.MINE_SHAFT} strokeWidth={6} />
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="SideBar-card">
        <div className="SideBar-card-wrapper">
          {dataSidebarSetting.map((item) => (
            <div
              key={item.key}
              className={classNames('SideBar-card-item flex items-center', {
                active: (item?.activePaths as string[])?.includes(pathname),
                disabled: item?.disabled,
              })}
              onClick={(): void => {
                if (isZaloApp() && item.popup) {
                  switch (item.key) {
                    case '7':
                      openWebview({
                        url: `https://drive.google.com/viewerng/viewer?embedded=true&url=https://h5.zdn.vn${ZALO_MINI_APP_BASE_PATH}/${
                          isZaloApp() && window.APP_VERSION ? window.APP_VERSION + OP : OP
                        }`,
                      });
                      break;
                    case '10':
                      setSheetBody(<ContactAdmin onBack={(): void => setVisibleBottomSheet(false)} />);
                      setVisibleBottomSheet(true);
                      break;

                    default:
                      break;
                  }
                } else if (item.link && !item.disabled) {
                  navigate(item.link);
                }
              }}
            >
              <div className="SideBar-card-item-icon">
                <img src={item.icon} alt="" />
              </div>
              <div className="SideBar-card-subtitle">{item.title}</div>
              <div className="SideBar-card-item-arrow" style={{ marginLeft: 'auto' }}>
                <Icon name={EIconName.AngleRight} color={EIconColor.MINE_SHAFT} strokeWidth={6} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );

  return (
    <div className="SideBar">
      <div className="SideBar-card flex items-center">
        <div className="SideBar-card-avatar">
          <Avatar image={myProfileState?.avatar} />
        </div>
        <div className="SideBar-card-info">
          <div className="SideBar-card-title">{myProfileState?.name}</div>
          <span
            className="SideBar-card-description capitalize"
            onClick={(): void => {
              if (isZaloApp()) {
                if (myProfileState.rankName) {
                  setSheetBody(
                    <Loyalty
                      rankLevel={myProfileState.rankLevel || 1}
                      rankColor={myProfileState.rankColor || ERankColors.POTENTIAL}
                      rankName={myProfileState.rankName}
                      rankIcon={myProfileState?.rankIcon || ''}
                      rankBG={myProfileState?.rankBG || ''}
                      rankPercentage={myProfileState?.rankPercentage || 0}
                      remainingAmountToNextRank={myProfileState?.remainingAmountToNextRank || 0}
                      onBack={(): void => setVisibleBottomSheet(false)}
                      discountPercentage={myProfileState.discountPercentage || 0}
                    />,
                  );
                  setVisibleBottomSheet(true);
                }
              } else {
                navigate(`${LayoutPaths.Profile}${Paths.Loyalty}`, {
                  state: {
                    myProfileState,
                  },
                });
              }
            }}
          >
            {i18next.t('Hạng: {{value}}', { value: myProfileState?.rankLabel || 'Chưa có' })}
          </span>
        </div>
        <div className="SideBar-card-setting flex items-center" style={{ marginLeft: 'auto', columnGap: '.4rem' }}>
          <Icon
            className="cursor-pointer"
            name={EIconName.Setting}
            color={EIconColor.ALUMINIUM}
            onClick={(): void => {
              navigate(`${LayoutPaths.Profile}${Paths.ProfileInformation}`);
            }}
          />
        </div>
      </div>

      {menu}

      <Drawer
        className="HeaderMobile"
        open={visibleBottomSheet}
        placement="bottom"
        onClose={(): void => setVisibleBottomSheet(false)}
        zIndex={9999999}
        closable={false}
        size="large"
        height="100vh"
        bodyStyle={{
          padding: 0,
        }}
      >
        {sheetBody}
      </Drawer>
    </div>
  );
};

export default SideBar;
