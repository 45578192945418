import i18next from 'i18next';
import React from 'react';

import { EIconColor } from '@/components/Icon/Icon.enums';
import { TIconProps } from './Icon.types';

const Svg: React.FC<TIconProps> = ({ color = EIconColor.BLACK }) => {
  return (
    <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M29.0625 15.7363C29.0625 16.9363 27.5883 17.9254 27.293 19.0316C26.9883 20.1754 27.7594 21.7691 27.1805 22.7699C26.5922 23.7871 24.8227 23.909 23.9977 24.734C23.1727 25.559 23.0508 27.3285 22.0336 27.9168C21.0328 28.4957 19.4391 27.7246 18.2953 28.0293C17.1891 28.3246 16.2 29.7988 15 29.7988C13.8 29.7988 12.8109 28.3246 11.7047 28.0293C10.5609 27.7246 8.96719 28.4957 7.96641 27.9168C6.94922 27.3285 6.82734 25.559 6.00234 24.734C5.17734 23.909 3.40781 23.7871 2.81953 22.7699C2.24062 21.7691 3.01172 20.1754 2.70703 19.0316C2.41172 17.9254 0.9375 16.9363 0.9375 15.7363C0.9375 14.5363 2.41172 13.5473 2.70703 12.441C3.01172 11.2973 2.24062 9.70352 2.81953 8.70273C3.40781 7.68555 5.17734 7.56367 6.00234 6.73867C6.82734 5.91367 6.94922 4.14414 7.96641 3.55586C8.96719 2.97695 10.5609 3.74805 11.7047 3.44336C12.8109 3.14805 13.8 1.67383 15 1.67383C16.2 1.67383 17.1891 3.14805 18.2953 3.44336C19.4391 3.74805 21.0328 2.97695 22.0336 3.55586C23.0508 4.14414 23.1727 5.91367 23.9977 6.73867C24.8227 7.56367 26.5922 7.68555 27.1805 8.70273C27.7594 9.70352 26.9883 11.2973 27.293 12.441C27.5883 13.5473 29.0625 14.5363 29.0625 15.7363Z"
        fill={color}
      />
      <path
        d="M19.0642 11.5455L13.711 16.8986L10.936 14.126C10.3337 13.5236 9.35635 13.5236 8.754 14.126C8.15166 14.7283 8.15166 15.7057 8.754 16.308L12.647 20.201C13.2329 20.7869 14.1845 20.7869 14.7704 20.201L21.2438 13.7275C21.8462 13.1252 21.8462 12.1479 21.2438 11.5455C20.6415 10.9432 19.6665 10.9432 19.0642 11.5455Z"
        fill="white"
      />
    </svg>
  );
};

export default Svg;
