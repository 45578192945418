export enum EButtonStyleType {
  PRIMARY_GRADIENT = 'PRIMARY_GRADIENT',
  PRIMARY_TRANSPARENT = 'PRIMARY_TRANSPARENT',
  PRIMARY_TEXT = 'PRIMARY_TEXT',
  PRIMARY_OUTLINE = 'PRIMARY_OUTLINE',

  TRANSPARENT = 'TRANSPARENT',

  PRIMARY = 'PRIMARY',

  GRAY_OUTLINE = 'GRAY_OUTLINE',
}
