import i18next from 'i18next';
import React from 'react';

import { Form } from 'antd';
import { useSelector } from 'react-redux';
import { Trans } from 'react-i18next';
import { Link } from '@/utils/router';

import Button, { EButtonStyleType } from '@/components/Button';
import Icon, { EIconColor, EIconName } from '@/components/Icon';
import Input from '@/components/Input';
import { Paths } from '@/pages/routers';
import { TRootState } from '@/redux/reducers';

import { isZaloApp } from '@/utils/functions';
import './Footer.scss';
import { TFooterProps } from './Footer.types.d';

const Footer: React.FC<TFooterProps> = () => {
  const [form] = Form.useForm();

  const categoriesState = useSelector((state: TRootState) => state.categoryReducer.getCategoriesResponse)?.data || [];

  const dataUsefulLink = [
    { link: '#', title: i18next.t('Giới thiệu') },
    { link: '#', title: i18next.t('Tin tức') },
    { link: '#', title: i18next.t('Tuyển dụng') },
    { link: '#', title: i18next.t('Liên hệ ') },
  ];

  const dataServicesLink = categoriesState?.map((item) => ({
    link: Paths.Category(String(item.id), item.slug),
    title: item.name,
  }));

  const dataSocialsLink = [
    { link: 'https://www.facebook.com/MyGuuu', iconName: EIconName.Facebook },
    { link: 'instagram', iconName: EIconName.Instagram },
    { link: 'youtube', iconName: EIconName.Youtube },
  ];

  return (
    <div className="Footer">
      {!isZaloApp() && (
        <div className="Footer-top">
          <div className="container">
            <div className="Footer-wrapper flex items-start flex-wrap">
              <div className="Footer-col">
                <h6 className="Footer-title">{i18next.t('Dịch vụ Myguu')}</h6>
                <ul
                  className="Footer-list"
                  style={{
                    columnCount: 2,
                  }}
                >
                  {dataServicesLink.map((item, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <li key={index} className="Footer-list-item">
                      <Link to={item.link}>{item.title}</Link>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="Footer-col">
                <h6 className="Footer-title">{i18next.t('Địa chỉ công ty')}</h6>
                <p className="Footer-description" style={{ marginBottom: '1.2rem' }}>
                  <strong>{i18next.t('Địa chỉ:')}</strong>
                  {i18next.t(` Tầng 6, tòa nhà hỗn hợp Sông Đà, Số 131 Trần Phú, P.Văn Quán, Q.Hà Đông, Tp.Hà Nội.`)}
                </p>
                <p className="Footer-description" style={{ marginBottom: '1.2rem' }}>
                  <strong>{i18next.t('Điện thoại:')}</strong> +1 602-922-9992
                </p>
                <p className="Footer-description">
                  <strong>{i18next.t('Email:')}</strong> info@vicimis.com
                </p>
              </div>

              <div className="Footer-col">
                <h6 className="Footer-title">{i18next.t('Về chúng tôi')}</h6>
                <ul className="Footer-list">
                  {dataUsefulLink.map((item, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <li key={index} className="Footer-list-item">
                      <Link to={item.link}>{item.title}</Link>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="Footer-col">
                <h6 className="Footer-description">
                  {i18next.t('Đăng kí để nhận tin tức mới nhất từ')} <strong>VICI</strong>
                </h6>
                <Form form={form}>
                  <div className="Footer-form flex items-center">
                    <div className="Footer-form-input">
                      <Form.Item name="email">
                        <Input size="small" placeholder={i18next.t('Nhập địa chỉ email của bạn')} />
                      </Form.Item>
                    </div>
                    <div className="Footer-form-submit">
                      <Button title={i18next.t('Đăng Ký')} size="small" styleType={EButtonStyleType.PRIMARY} />
                    </div>
                  </div>
                </Form>
                <div className="Footer-socials flex items-center">
                  {dataSocialsLink.map((item, index) => (
                    <a
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      className="Footer-socials-item flex items-center justify-center"
                      href={item.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Icon name={item.iconName} color={EIconColor.WHITE} />
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="Footer-bottom">
        <div className="container">
          <div className="Footer-wrapper flex items-center justify-between">
            <p className="Footer-subtitle">
              <Trans
                i18nKey="Copyright 2023 © 2021 <strong>MYGUU</strong>. All rights reserved"
                components={{ strong: <strong /> }}
              />
            </p>
            <p className="Footer-description">{i18next.t('Design by APSOFT')}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
