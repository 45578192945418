import { Tabs as AntdTabs } from 'antd';
import classNames from 'classnames';
import i18next from 'i18next';
import React from 'react';

import { ETabsStyleType } from '@/components/Tabs/Tabs.enums';
import './Tabs.scss';
import { TTabsProps } from './Tabs.types.d';

const { TabPane } = AntdTabs;

const Tabs: React.FC<TTabsProps> = ({
  defaultActiveKey,
  size,
  data = [],
  className,
  activeKey,
  onChange,
  onTabClick,
  styleType = ETabsStyleType.TAG,
}) => {
  return (
    <div className={classNames('Tabs', className, styleType)}>
      <AntdTabs
        activeKey={activeKey}
        defaultActiveKey={defaultActiveKey}
        size={size}
        onChange={onChange}
        onTabClick={onTabClick}
      >
        {data.map((item) => (
          <TabPane tab={item.title} key={item.key}>
            {item.children}
          </TabPane>
        ))}
      </AntdTabs>
    </div>
  );
};

export default Tabs;
