import { Redirect, Router } from '@reach/router';
import classNames from 'classnames';
import i18next from 'i18next';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authorize, getAccessToken, getLocation, getSetting } from 'zmp-sdk/apis';

import ModalRequireTurnOnShareLocation from '@/containers/ModalRequireTurnOnShareLocation';
import ModalRequireTurnOnShareLocationMiniApp from '@/containers/ModalRequireTurnOnShareLocationMiniApp';
import Guest from '@/layouts/Guest';
import Profile from '@/layouts/Profile/Profile';
import { LayoutPaths, Pages, Paths, ProtectedRoute, PublicRoute } from '@/pages/routers';
import {
  getAddressGeocodeAction,
  getCategoriesAction,
  getMyProfileAction,
  getNotificationUnreadCountAction,
  uiActions,
} from '@/redux/actions';
import { TRootState } from '@/redux/reducers';
import Helpers from '@/services/helpers';
import { useModalState } from '@/utils/hooks';

import 'moment/locale/vi';
import { ZALO_MINI_APP_BASE_PATH } from './common/constants';
import { isZaloApp } from './utils/functions';
import BackgroundImage from '@/assets/images/main-background.png';

const App: React.FC = () => {
  const dispatch = useDispatch();

  const [atk, setAtk] = React.useState('');

  const [
    modalRequireTurnOnShareLocationState,
    handleOpenModalRequireTurnOnShareLocation,
    handleCloseModalRequireTurnOnShareLocation,
  ] = useModalState();

  const [
    modalRequireTurnOnShareLocationMiniAppState,
    handleOpenModalRequireTurnOnShareLocationMiniApp,
    handleCloseModalRequireTurnOnShareLocationMiniApp,
  ] = useModalState();

  const myProfileState = useSelector((state: TRootState) => state.userReducer.getMyProfileResponse);
  const appGeoLoactionState = useSelector((state: TRootState) => state.uiReducer.geoAppLocation);
  const pageConfigState = useSelector((state: TRootState) => state.uiReducer.pageConfig);

  const getGeoLocation = (): void => {
    if (isZaloApp()) {
      try {
        getLocation().then(async (data) => {
          const endpoint = 'https://graph.zalo.me/v2.0/me/info';
          const userAccessToken = await getAccessToken();
          const secretKey = 'REB5eYR8yWaDpK51huqS';

          const requestHeaders: HeadersInit = new Headers();
          requestHeaders.set('access_token', userAccessToken);
          requestHeaders.set('code', data.token || '');
          requestHeaders.set('secret_key', secretKey);

          const responseLocation = await fetch(endpoint, {
            method: 'GET',
            headers: requestHeaders,
          });
          if (responseLocation.ok) {
            const responseData = await responseLocation.json();
            const { latitude, longitude } = responseData.data;
            dispatch(uiActions.setGeoLocationApp({ latitude, longitude }));
          }
        });
      } catch (error) {
        console.log(error);
      }
    } else if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position): void => {
          const { latitude, longitude } = position.coords;
          dispatch(uiActions.setGeoLocationApp({ latitude, longitude }));
        },
        (err): void => {
          handleOpenModalRequireTurnOnShareLocation(err);
        },
      );
    } else {
      handleOpenModalRequireTurnOnShareLocation();
    }
  };

  const getCategories = useCallback(() => {
    dispatch(getCategoriesAction.request({}));
  }, [dispatch]);

  const getNotificationUnreadCount = useCallback(() => {
    dispatch(getNotificationUnreadCountAction.request({}));
  }, [dispatch]);

  const getAddressGeocode = useCallback(() => {
    if (appGeoLoactionState && myProfileState) {
      dispatch(
        getAddressGeocodeAction.request({
          params: { lat: appGeoLoactionState?.latitude, lng: appGeoLoactionState?.longitude },
        }),
      );
    }
  }, [dispatch, appGeoLoactionState, myProfileState]);

  useEffect(() => {
    if (isZaloApp()) {
      Helpers.getAccessTokenZaloMiniApp().then((token) => {
        setAtk(token);
      });
    } else {
      setAtk(Helpers.getAccessToken());
    }
  }, []);

  useEffect(() => {
    if (myProfileState) {
      if (isZaloApp()) {
        getSetting()
          .then(async (settings) => {
            if (!settings.authSetting['scope.userLocation']) {
              handleOpenModalRequireTurnOnShareLocationMiniApp();
            } else {
              getGeoLocation();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        getGeoLocation();
      }
      getNotificationUnreadCount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myProfileState]);

  useEffect(() => {
    if (atk && !myProfileState) {
      dispatch(getMyProfileAction.request({}));
    }
  }, [dispatch, myProfileState, atk]);

  useEffect(() => {
    getAddressGeocode();
  }, [getAddressGeocode]);

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  const renderRouter = (): any => {
    if (isZaloApp()) {
      return (
        <Router primary={false} basepath={ZALO_MINI_APP_BASE_PATH}>
          <Guest path={LayoutPaths.Guest}>
            <PublicRoute path={Paths.Home} component={Pages.Home} />
            <PublicRoute path={Paths.ShopDetail()} component={Pages.ShopDetail} title="" backgroundType="hide" />
            <PublicRoute
              path={Paths.ServiceDetail()}
              component={Pages.ServiceDetail}
              title={i18next.t('Dịch Vụ')}
              backgroundType="hide"
            />
            <PublicRoute path={Paths.Category()} component={Pages.Category} />
            <PublicRoute
              path={Paths.Search}
              component={Pages.Search}
              title={i18next.t('Tìm kiếm')}
              backgroundType="large"
            />
            <PublicRoute path={Paths.Services} component={Pages.Services} />
            <PublicRoute path={Paths.Shops} component={Pages.Shops} />
            <ProtectedRoute path={Paths.Booking()} component={Pages.Booking} />
            <ProtectedRoute
              path={Paths.Account}
              component={Pages.Account}
              title={i18next.t('Tài Khoản')}
              hideBackBtn
              backgroundType="large"
            />
            <PublicRoute path={Paths.Policy} component={Pages.Policy} title={i18next.t('Chính sách bảo mật')} />
            <PublicRoute
              path={Paths.CancellationPolicy}
              component={Pages.CancellationPolicy}
              title={i18next.t('Chính sách đổi lịch & hoàn tiền')}
            />
            <ProtectedRoute path={Paths.PaymentResult} component={Pages.Booking} />

            <Redirect noThrow from={Paths.Rest} to={`${ZALO_MINI_APP_BASE_PATH}${LayoutPaths.Guest}${Paths.Home}`} />
          </Guest>

          <Profile path={LayoutPaths.Profile}>
            <ProtectedRoute
              path={Paths.MySchedules}
              component={Pages.MySchedules}
              title={i18next.t('Lịch hẹn của tôi')}
            />
            <ProtectedRoute
              path={Paths.MyScheduleDetail()}
              component={Pages.MyScheduleDetail}
              title={i18next.t('Chi tiết lịch đặt')}
            />
            <ProtectedRoute
              path={Paths.ProfileInformation}
              component={Pages.ProfileInformation}
              title={i18next.t('Thông tin cá nhân')}
            />
            <ProtectedRoute
              path={Paths.FavoritesShop}
              component={Pages.FavoritesShop}
              title={i18next.t('Danh Sách Yêu Thích')}
            />
            <ProtectedRoute path={Paths.Vouchers} component={Pages.Vouchers} title={i18next.t('Ví Vouchers')} />
            <ProtectedRoute path={Paths.Notifications} component={Pages.Notifications} title={i18next.t('Thông Báo')} />
            <ProtectedRoute path={Paths.MyAddress} component={Pages.MyAddress} />
            <ProtectedRoute path={Paths.Loyalty} component={Pages.Loyalty} />
            <ProtectedRoute
              path={Paths.MyPoint}
              component={Pages.MyPoint}
              title={i18next.t('Điểm Gu')}
              backgroundType="large"
            />
            <ProtectedRoute
              path={Paths.ChangePassword}
              component={Pages.ChangePassword}
              title={i18next.t('Thay Đổi Mật Khẩu')}
            />
            <ProtectedRoute
              path={Paths.ContactAdmin}
              component={Pages.ContactAdmin}
              title={i18next.t('Liên Hệ Admin')}
            />

            <Redirect noThrow from={Paths.Rest} to={`${ZALO_MINI_APP_BASE_PATH}${LayoutPaths.Guest}${Paths.Home}`} />
          </Profile>
        </Router>
      );
    }
    return (
      <Router primary={false}>
        <Guest path={LayoutPaths.Guest}>
          <PublicRoute path={Paths.Home} component={Pages.Home} />
          <PublicRoute path={Paths.ShopDetail()} component={Pages.ShopDetail} />
          <PublicRoute path={Paths.ServiceDetail()} component={Pages.ServiceDetail} />
          <PublicRoute path={Paths.Category()} component={Pages.Category} />
          <PublicRoute path={Paths.Search} component={Pages.Search} />
          <PublicRoute path={Paths.Services} component={Pages.Services} />
          <PublicRoute path={Paths.Shops} component={Pages.Shops} />
          <ProtectedRoute path={Paths.Booking()} component={Pages.Booking} />
          <ProtectedRoute path={Paths.Account} component={Pages.Account} />
          <PublicRoute path={Paths.Policy} component={Pages.Policy} />
          <PublicRoute path={Paths.CancellationPolicy} component={Pages.CancellationPolicy} />

          <Redirect noThrow from={Paths.Rest} to={`${LayoutPaths.Guest}${Paths.Home}`} />
        </Guest>

        <Profile path={LayoutPaths.Profile}>
          <ProtectedRoute path={Paths.MySchedules} component={Pages.MySchedules} />
          <ProtectedRoute path={Paths.MyScheduleDetail()} component={Pages.MyScheduleDetail} />
          <ProtectedRoute path={Paths.ProfileInformation} component={Pages.ProfileInformation} />
          <ProtectedRoute path={Paths.FavoritesShop} component={Pages.FavoritesShop} />
          <ProtectedRoute path={Paths.Vouchers} component={Pages.Vouchers} />
          <ProtectedRoute path={Paths.Notifications} component={Pages.Notifications} />
          <ProtectedRoute path={Paths.MyAddress} component={Pages.MyAddress} />
          <ProtectedRoute path={Paths.Loyalty} component={Pages.Loyalty} />
          <ProtectedRoute path={Paths.MyPoint} component={Pages.MyPoint} />
          <ProtectedRoute path={Paths.ChangePassword} component={Pages.ChangePassword} />
          <ProtectedRoute path={Paths.ContactAdmin} component={Pages.ContactAdmin} />

          <Redirect noThrow from={Paths.Rest} to={`${LayoutPaths.Guest}${Paths.Home}`} />
        </Profile>
      </Router>
    );
  };
  return (
    <div
      className={classNames('App', { 'mini-app': isZaloApp() })}
      style={{
        backgroundImage: `url(${
          isZaloApp() && window.APP_VERSION ? window.APP_VERSION + BackgroundImage : BackgroundImage
        })`,
        backgroundSize: 'cover',
      }}
    >
      <ModalRequireTurnOnShareLocation
        {...modalRequireTurnOnShareLocationState}
        onClose={handleCloseModalRequireTurnOnShareLocation}
        onSubmit={(): void => {
          getGeoLocation();
        }}
      />
      <ModalRequireTurnOnShareLocationMiniApp
        {...modalRequireTurnOnShareLocationMiniAppState}
        onClose={handleCloseModalRequireTurnOnShareLocationMiniApp}
        onSubmit={(): void => {
          modalRequireTurnOnShareLocationMiniAppState.visible = false;
          authorize({
            scopes: ['scope.userLocation'],
          })
            .then(() => {
              getGeoLocation();
            })
            .catch((error) => {
              console.log(error);
            });
        }}
      />

      {renderRouter()}
    </div>
  );
};

export default App;
