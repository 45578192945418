import { all, takeLatest } from 'redux-saga/effects';

import { getHistoryPointAction, getMyPointAction } from '@/redux/actions';

import { getHistoryPointSaga } from './get-history-point';
import { getMyPointSaga } from './get-my-point';

export default function* root(): Generator {
  yield all([
    takeLatest(getHistoryPointAction.request.type, getHistoryPointSaga),
    takeLatest(getMyPointAction.request.type, getMyPointSaga),
  ]);
}
