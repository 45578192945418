import i18next from 'i18next';
import React from 'react';

import { EIconColor } from './Icon.enums';
import { TIconProps } from './Icon.types';

const Svg: React.FC<TIconProps> = ({ color = EIconColor.BLACK }) => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.7172 2.5C13.4735 2.5 14.1582 2.92 14.5363 3.54C14.7203 3.84 14.8429 4.21 14.8122 4.6C14.7918 4.9 14.8838 5.2 15.0473 5.48C15.5685 6.33 16.7233 6.65 17.6226 6.17C18.6344 5.59 19.9118 5.94 20.4943 6.93L21.179 8.11C21.7718 9.1 21.4447 10.37 20.4228 10.94C19.5541 11.45 19.2475 12.58 19.7687 13.44C19.9322 13.71 20.1162 13.94 20.4023 14.08C20.76 14.27 21.036 14.57 21.2301 14.87C21.6083 15.49 21.5776 16.25 21.2097 16.92L20.4943 18.12C20.1162 18.76 19.411 19.16 18.6855 19.16C18.3278 19.16 17.9292 19.06 17.6022 18.86C17.3365 18.69 17.0299 18.63 16.7029 18.63C15.6911 18.63 14.8429 19.46 14.8122 20.45C14.8122 21.6 13.872 22.5 12.6968 22.5H11.3069C10.1215 22.5 9.18125 21.6 9.18125 20.45C9.16081 19.46 8.31259 18.63 7.30085 18.63C6.96361 18.63 6.65702 18.69 6.40153 18.86C6.0745 19.06 5.66572 19.16 5.31825 19.16C4.58245 19.16 3.87729 18.76 3.49917 18.12L2.79402 16.92C2.4159 16.27 2.39546 15.49 2.77358 14.87C2.93709 14.57 3.24368 14.27 3.59115 14.08C3.87729 13.94 4.06125 13.71 4.23498 13.44C4.74596 12.58 4.43937 11.45 3.57071 10.94C2.55897 10.37 2.23194 9.1 2.81446 8.11L3.49917 6.93C4.09191 5.94 5.35913 5.59 6.38109 6.17C7.27019 6.65 8.425 6.33 8.9462 5.48C9.10972 5.2 9.20169 4.9 9.18125 4.6C9.16081 4.21 9.27323 3.84 9.4674 3.54C9.84553 2.92 10.5302 2.52 11.2763 2.5H12.7172ZM12.0121 9.68C10.4076 9.68 9.10972 10.94 9.10972 12.51C9.10972 14.08 10.4076 15.33 12.0121 15.33C13.6165 15.33 14.8838 14.08 14.8838 12.51C14.8838 10.94 13.6165 9.68 12.0121 9.68Z"
        fill={color}
      />
    </svg>
  );
};

export default Svg;
