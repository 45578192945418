import { THistoryPoint, TPaging } from '@/common/models';
import ApiService from '@/services/api';

// TYPES

export type TGetHistoryPointParams = {
  type?: string;
  limit?: number;
  page?: number;
};

export type TGetHistoryPointMaterials = {
  params?: TGetHistoryPointParams;
};

export type TGetHistoryPointResponse = {
  data?: THistoryPoint[];
  paging?: TPaging;
};

// FUNCTION

export const getHistoryPoint = async ({ params }: TGetHistoryPointMaterials): Promise<TGetHistoryPointResponse> => {
  const response = await ApiService.get(`/user/history-point`, { params });
  return response?.data;
};
