import i18next from 'i18next';
import React from 'react';

import { EIconColor } from './Icon.enums';
import { TIconProps } from './Icon.types';

const Svg: React.FC<TIconProps> = ({ color = EIconColor.BLACK }) => {
  return (
    <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.0852 9.7578C16.8012 9.7578 18.1972 11.1468 18.1972 12.8538C18.1972 14.5608 16.8012 15.9498 15.0852 15.9498C13.3682 15.9498 11.9712 14.5608 11.9712 12.8538C11.9712 11.1468 13.3682 9.7578 15.0852 9.7578ZM15.0852 11.2578C14.1952 11.2578 13.4712 11.9738 13.4712 12.8538C13.4712 13.7348 14.1952 14.4498 15.0852 14.4498C15.9742 14.4498 16.6972 13.7348 16.6972 12.8538C16.6972 11.9738 15.9742 11.2578 15.0852 11.2578ZM7.77757 12.1426C8.19157 12.1426 8.52757 12.4786 8.52757 12.8926C8.52757 13.3066 8.19157 13.6426 7.77757 13.6426H1.47657C1.06257 13.6426 0.726566 13.3066 0.726566 12.8926C0.726566 12.4786 1.06257 12.1426 1.47657 12.1426H7.77757ZM3.81027 0.0498047C5.52727 0.0498047 6.92327 1.4398 6.92327 3.1468C6.92327 4.8538 5.52727 6.2418 3.81027 6.2418C2.09427 6.2418 0.697266 4.8538 0.697266 3.1468C0.697266 1.4398 2.09427 0.0498047 3.81027 0.0498047ZM3.81027 1.5498C2.92127 1.5498 2.19727 2.2658 2.19727 3.1468C2.19727 4.0268 2.92127 4.7418 3.81027 4.7418C4.70027 4.7418 5.42327 4.0268 5.42327 3.1468C5.42327 2.2658 4.70027 1.5498 3.81027 1.5498ZM16.8884 2.4502C17.3024 2.4502 17.6384 2.7862 17.6384 3.2002C17.6384 3.6142 17.3024 3.9502 16.8884 3.9502H10.5884C10.1744 3.9502 9.83837 3.6142 9.83837 3.2002C9.83837 2.7862 10.1744 2.4502 10.5884 2.4502H16.8884Z"
        fill={color}
      />
    </svg>
  );
};

export default Svg;
