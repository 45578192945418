import i18next from 'i18next';
import React from 'react';

import { EIconColor } from './Icon.enums';
import { TIconProps } from './Icon.types';

const Svg: React.FC<TIconProps> = ({ color = EIconColor.BLACK }) => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.0805 3.03277C20.0418 2.51542 19.6117 2.11494 19.0929 2.11328H17.0221C16.5034 2.11502 16.0733 2.5155 16.0347 3.03277L15.4183 11.6599C15.4053 11.8417 15.5422 11.9996 15.724 12.0126C15.7318 12.0131 15.7396 12.0134 15.7475 12.0134H17.0675V17.9235L16.4127 21.5247C16.3802 21.704 16.4993 21.8757 16.6786 21.9082C16.698 21.9118 16.7177 21.9135 16.7375 21.9135H19.3775C19.5598 21.9135 19.7075 21.7658 19.7075 21.5835C19.7075 21.5638 19.7057 21.544 19.7022 21.5245L19.0475 17.924V12.0134H20.3675C20.5497 12.0134 20.6975 11.8656 20.6975 11.6834C20.6975 11.6755 20.6972 11.6677 20.6966 11.6599L20.0805 3.03277ZM17.1329 21.2535L17.6729 18.2835H18.442L18.982 21.2535H17.1329ZM18.3875 17.6234H17.7275V12.0134H18.3875V17.6234ZM16.1019 11.3534L16.6929 3.07982C16.7058 2.90733 16.8492 2.7738 17.0221 2.77327H19.0929C19.2658 2.77385 19.4091 2.90737 19.422 3.07982L20.0131 11.3534H16.1019Z"
        fill={color}
      />
      <path
        d="M11.875 21.914C12.5632 21.9122 13.1785 21.4851 13.4208 20.841C13.92 19.5008 14.7573 17.0008 14.7573 15.3139C14.7573 13.4633 13.8698 12.4831 13.1254 11.9868C12.7168 11.7173 12.2638 11.5222 11.7873 11.4103V9.04386C11.7873 8.86159 11.6395 8.71387 11.4573 8.71387H7.49727C7.31501 8.71387 7.16728 8.86164 7.16728 9.04386V11.4103C6.69076 11.5222 6.23781 11.7173 5.82921 11.9868C5.08478 12.4831 4.19727 13.4633 4.19727 15.3139C4.19727 17.0008 5.03463 19.5008 5.53376 20.841C5.77608 21.4851 6.39142 21.9122 7.07957 21.914H11.875ZM11.1273 9.37385V10.0339H10.4673C10.285 10.0339 10.1373 10.1816 10.1373 10.3639C10.1373 10.5461 10.2851 10.6939 10.4673 10.6939H11.1273V11.3539H7.82727V10.6939H9.14728C9.32955 10.6939 9.47727 10.5461 9.47727 10.3639C9.47727 10.1816 9.3295 10.0339 9.14728 10.0339H7.82727V9.37385H11.1273ZM7.5179 12.0139H11.4367C11.6708 12.0456 13.8622 12.4099 14.0798 14.9042L12.5862 15.8546C11.8804 16.3032 10.9875 16.3356 10.251 15.9395L8.53053 15.0131C7.67925 14.5554 6.65867 14.5401 5.79401 14.9718L4.85928 15.4393C4.85812 15.397 4.85725 15.3552 4.85725 15.3139C4.85725 12.446 7.27616 12.0466 7.5179 12.0139ZM4.91751 16.1479L6.08901 15.5622C6.76158 15.2263 7.55542 15.2383 8.21752 15.5943L9.93797 16.5207C10.8849 17.03 12.033 16.9882 12.9405 16.4115L14.0836 15.6841C14.0025 16.8778 13.5628 18.41 13.1596 19.6041H5.79484C5.4424 18.5598 5.06184 17.2568 4.91751 16.1479ZM6.15229 20.6105C6.11203 20.5024 6.06942 20.3861 6.02527 20.2639H12.9294C12.8852 20.3862 12.8427 20.5026 12.8023 20.6105C12.657 20.9968 12.2878 21.253 11.875 21.254H7.07957C6.66679 21.253 6.29765 20.9968 6.15229 20.6105Z"
        fill={color}
      />
    </svg>
  );
};

export default Svg;
