import { useLocation, useNavigate } from '@reach/router';
import classNames from 'classnames';
import React, { MutableRefObject, useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import Button, { EButtonStyleType } from '@/components/Button';
import { EIconColor, EIconName } from '@/components/Icon';
import { TRootState } from '@/redux/reducers';
import './MiniAppHeader.scss';
import { TMiniAppHeaderProps } from './MiniAppHeader.types';

const MiniAppHeader: React.FC<TMiniAppHeaderProps> = () => {
  const navigate = useNavigate();
  const location: any = useLocation();

  const bgRef: MutableRefObject<HTMLDivElement | null> = useRef(null);
  const titleRef: MutableRefObject<HTMLSpanElement | null> = useRef(null);
  const pageConfigState = useSelector((state: TRootState) => state.uiReducer.pageConfig);

  const prefixCls = 'MiniAppHeader';

  const handleBackClick = (): void => {
    // event.preventDefault();
    // event.stopPropagation();
    navigate(-1);
  };

  const listenScrollEvent = useCallback((): void => {
    if (pageConfigState?.backgroundType === 'hide') {
      if (bgRef.current) {
        bgRef.current.style.opacity = `${window.scrollY / 100}`;
      }

      if (titleRef.current) {
        titleRef.current.style.opacity = `${window.scrollY / 100}`;
      }
    }
    if (pageConfigState?.backgroundType === 'large') {
      if (bgRef.current) {
        const height = (15 * (100 - window.scrollY)) / 100;

        if (height < 0) {
          bgRef.current.style.zIndex = '99';
          bgRef.current.style.opacity = '1';
        } else {
          bgRef.current.style.opacity = `${1 - window.scrollY / 100}`;
          bgRef.current.style.zIndex = '0';
          bgRef.current.style.height = `calc(${height}vh + var(--zaui-safe-area-inset-top, env(safe-area-inset-top)) + 44px)`;
        }
      }
    }
  }, [pageConfigState]);

  useEffect(() => {
    if (pageConfigState?.backgroundType === 'hide' || pageConfigState?.backgroundType === 'large') {
      window.onscroll = listenScrollEvent;
    } else {
      window.onscroll = null;
    }
  }, [listenScrollEvent, pageConfigState]);

  useEffect(() => {
    return (): void => {
      window.onscroll = null;
    };
  }, []);

  return (
    <div className={classNames(prefixCls)}>
      <div className={classNames(`${prefixCls}-wrapper`)}>
        <div className={`${prefixCls}-header`}>
          {!pageConfigState?.hideBackBtn && (
            <span className={`${prefixCls}-back`}>
              <Button
                className={`${prefixCls}-back-btn`}
                styleType={EButtonStyleType.TRANSPARENT}
                iconColor={EIconColor.WHITE}
                onClick={handleBackClick}
                iconName={EIconName.FullArrowLeft}
              />
            </span>
          )}
          <span
            ref={titleRef}
            className={classNames(`${prefixCls}-title`, {
              [`title-${pageConfigState?.backgroundType}`]: pageConfigState?.backgroundType,
            })}
            style={{
              opacity: pageConfigState?.backgroundType === 'hide' ? 0 : 1,
            }}
          >
            {pageConfigState?.title || location?.state?.title || ''}
          </span>
        </div>
        <div
          ref={bgRef}
          className={classNames(`${prefixCls}-bg`, {
            [`bg-${pageConfigState?.backgroundType}`]: pageConfigState?.backgroundType,
          })}
          style={{
            opacity: pageConfigState?.backgroundType === 'hide' ? 0 : 1,
          }}
        />
      </div>
    </div>
  );
};

export default MiniAppHeader;
