export enum EResponseCode {
  OK = 200,
  CREATED = 201,
  ACCEPTED = 202,
  NON_AUTHORITATIVE_INFORMATION = 203,
  NO_CONTENT = 204,
  RESET_CONTENT = 205,
  PARTIAL_CONTENT = 206,
  MULTI_STATUS = 207,
  ALREADY_REPORTED = 208,
  I_AM_USED = 226,

  MULTIPLE_CHOICE = 300,
  MOVED_PERMANENTLY = 301,
  FOUND = 302,
  SEE_OTHER = 303,
  NOT_MODIFIED = 304,
  TEMPORARY_REDIRECT = 307,
  PERMANENT_REDIRECT = 308,

  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  PAYMENT_REQUIRED = 402,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  METHOD_NOT_ALLOWED = 405,
  NOT_ACCEPTABLE = 406,
  PROXY_AUTHENTICATION_REQUIRED = 407,
  REQUEST_TIMEOUT = 408,
  CONFLICT = 409,
  GONE = 410,
  LENGTH_REQUIRED = 411,
  PRECONDITION_FAILED = 412,
  PAYLOAD_TOO_LARGE = 413,
  URI_TOO_LONG = 414,
  UNSUPPORTED_MEDIA_TYPE = 415,
  RANGE_NOT_SATISFIABLE = 416,
  EXPECTATION_FAILED = 417,
  I_AM_A_TEAPOT = 418,
  MISDIRECTED_REQUEST = 421,
  UNPROCESSABLE_ENTITY = 422,
  LOCKED = 423,
  FAILED_DEPENDENCY = 424,
  TOO_EARLY = 425,
  UPGRADE_REQUIRED = 426,
  PRECONDITION_REQUIRED = 428,
  TOO_MANY_REQUESTS = 429,
  REQUEST_HEADER_FIELDS_TOO_LARGE = 431,
  UNAVAILABLE_FOR_LEGAL_REASONS = 451,

  INTERNAL_SERVER_ERROR = 500,
  NOT_IMPLEMENTED = 501,
  BAD_GATEWAY = 502,
  SERVICE_UNAVAILABLE = 503,
  GATEWAY_TIMEOUT = 504,
  HTTP_VERSION_NOT_SUPPORTED = 505,
  VARIANT_ALSO_NEGOTIATES = 506,
  INSUFFICIENT_STORAGE = 507,
  LOOP_DETECTED = 508,
  NOT_EXTENDED = 510,
  NETWORK_AUTHENTICATION_REQUIRED = 511,
}

export enum ETypeNotification {
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  INFO = 'info',
}

export enum ETimeoutDebounce {
  SEARCH = 200,
}

export enum EEmpty {
  DASH = '-',
}

export enum EFormat {
  'DD/MM/YYYY' = 'DD/MM/YYYY',
  'DD/MM/YYYY - HH:mm' = 'DD/MM/YYYY - HH:mm',
  'DD/MM/YYYY - HH:mm A' = 'DD/MM/YYYY - HH:mm A',
  'DD-MM-YYYY' = 'DD-MM-YYYY',
  'DD-MM-YYYY - HH:mm' = 'DD-MM-YYYY - HH:mm',
  'HH:mm' = 'HH:mm',
}

export enum ERole {
  STORE = 1,
  USER = 2,
  ADMIN = 3,
}

export enum EPlatform {
  IOS = 'ios',
  ANDROID = 'android',
  WEB = 'web',
}

export enum EBannerScreen {
  HOME = 'HOME',
}

export enum EBannerType {
  OVERVIEW = 'OVERVIEW',
  PROMINENT_PLACE = 'PROMINENT_PLACE',
}

export enum EVoucherType {
  APP = 1,
  STORE = 2,
  OTHER = 3,
}

export enum EOrderStatus {
  SCHEDULE = 'SCHEDULE',
  IN_COMMING = 'IN_COMMING',
  PENDING = 'PENDING',
  COMPLETE = 'COMPLETE',
  CANCELED = 'CANCELED',
}

export enum EOrderProcess {
  PENDING = 'PENDING',
  CONFIRMED = 'CONFIRMED',
  COMPLETE = 'SUCCESS',
  CANCEL = 'CANCEL',
}

export enum ENotificationStatus {
  UNREAD = 1,
  READ = 2,
}

export enum ERankLevels {
  POTENTIAL = 1,
  BRONZE = 2,
  SILVER = 3,
  GOLD = 4,
  PLATINUM = 5,
  DIAMOND = 6,
}

export enum ERankNames {
  POTENTIAL = 'Tiềm Năng',
  BRONZE = 'Đồng',
  SILVER = 'Bạc',
  GOLD = 'Vàng',
  PLATINUM = 'Bạch Kim',
  DIAMOND = 'Kim Cương',
}

export enum ERankColors {
  POTENTIAL = '#E4DFE0',
  BRONZE = '#D8B79A',
  SILVER = '#92ECFF',
  GOLD = '#FFA1D6',
  PLATINUM = '#6FC4EE',
  DIAMOND = '#BB3BF8',
}

export enum EPaymentStatus {
  NONE = 'NONE',
  REQUESTED = 'REQUESTED',
  PROCESSING = 'PROCESSING',
  SUCCESS = 'SUCCESS',
  FAIL = 'FAIL',
}
