import i18next from 'i18next';
import React from 'react';

import { EIconColor } from './Icon.enums';
import { TIconProps } from './Icon.types';

const Svg: React.FC<TIconProps> = ({ color = EIconColor.BLACK, strokeWidth = 3 }) => {
  return (
    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.29304 0.792969L7.70726 2.20718L3.91436 6.00008H16.0002V8.00008H3.91436L7.70726 11.793L6.29304 13.2072L0.0859375 7.00008L6.29304 0.792969Z"
        fill={color}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
};

export default Svg;
