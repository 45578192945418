import { createReducer } from 'deox';

import { TGetHistoryPointResponse, TGetMyPointResponse } from '@/services/api/loyalty';
import { getHistoryPointAction, getMyPointAction } from '@/redux/actions';
import { getHistoryPointUpdateState } from './get-history-point';
import { getMyPointUpdateState } from './get-my-point';

export type TLoyaltyState = {
  getHistoryPointResponse?: TGetHistoryPointResponse;
  getMyPointResponse?: TGetMyPointResponse;
};

const initialState: TLoyaltyState = {
  getHistoryPointResponse: undefined,
  getMyPointResponse: undefined,
};

const LoyaltyReducer = createReducer(initialState, (handleAction) => [
  handleAction(getHistoryPointAction.success, getHistoryPointUpdateState),
  handleAction(getMyPointAction.success, getMyPointUpdateState),
]);

export default LoyaltyReducer;
