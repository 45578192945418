import { createActionCreator } from 'deox';

import { TGetMyPointMaterials, TGetMyPointResponse } from '@/services/api/loyalty/get-my-point';

// CONSTANTS

export enum EGetMyPointAction {
  GET_MY_POINT = 'GET_MY_POINT',
  GET_MY_POINT_REQUEST = 'GET_MY_POINT_REQUEST',
  GET_MY_POINT_SUCCESS = 'GET_MY_POINT_SUCCESS',
  GET_MY_POINT_FAILED = 'GET_MY_POINT_FAILED',
}

// TYPES

export type TGetMyPointRequest = {
  type: EGetMyPointAction.GET_MY_POINT_REQUEST;
  payload: {
    materials: TGetMyPointMaterials;
    successCallback?: (response: TGetMyPointResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetMyPointSuccess = {
  type: EGetMyPointAction.GET_MY_POINT_SUCCESS;
  payload: { response: TGetMyPointResponse };
};

export type TGetMyPointFailed = { type: EGetMyPointAction.GET_MY_POINT_FAILED };

// FUNCTION

export const getMyPointAction = {
  request: createActionCreator(
    EGetMyPointAction.GET_MY_POINT_REQUEST,
    (resolve) =>
      (
        materials: TGetMyPointMaterials,
        successCallback?: (response: TGetMyPointResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetMyPointRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EGetMyPointAction.GET_MY_POINT_SUCCESS,
    (resolve) =>
      (response: TGetMyPointResponse): TGetMyPointSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EGetMyPointAction.GET_MY_POINT_FAILED,
    (resolve) =>
      (error: unknown): TGetMyPointFailed =>
        resolve({ error }),
  ),
};
