import classNames from 'classnames';
import i18next from 'i18next';
import React, { useEffect, useRef } from 'react';

import './Center.scss';
import { TCenterProps } from './Center.types.d';

const Center: React.FC<TCenterProps> = (props) => {
  const {
    id,
    children,
    className,
    style,
    max = 'var(--measure)',
    andText = false,
    gutters,
    intrinsic = false,
    ...rest
  } = props;

  const prefixCls = 'Center';
  const elementRef = useRef<HTMLDivElement>(null);
  const classes = classNames(prefixCls, className);
  const centerStyle = {
    ...(style || {}),
  };

  useEffect(() => {
    const render = (): void => {
      if (elementRef.current) {
        const i = `Center-${[max, andText, gutters, intrinsic].join('')}`;
        elementRef.current.dataset.i = i;

        if (!document.getElementById(i)) {
          const styleEl = document.createElement('style');
          styleEl.id = i;
          styleEl.innerHTML = `
                [data-i="${i}"] {
                    max-width: ${max};
                    ${
                      gutters
                        ? `
                    padding-inline-start: ${gutters};
                    padding-inline-end: ${gutters};`
                        : ''
                    }
                    ${andText ? `text-align: center;` : ''}
                    ${
                      intrinsic
                        ? `
                    display: flex;
                    flex-direction: column;
                    align-items: center;`
                        : ''
                    }
                  }`
            .replace(/\s\s+/g, ' ')
            .trim();
          document.head.appendChild(styleEl);
        }
      }
    };
    render();
  }, [max, andText, gutters, intrinsic]);

  return (
    <div {...rest} ref={elementRef} id={id} style={centerStyle} className={classes}>
      {children}
    </div>
  );
};

export default Center;
