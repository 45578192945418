import i18next from 'i18next';
import React from 'react';

import { TIconProps } from './Icon.types';

const Svg: React.FC<TIconProps> = () => {
  return (
    <svg width="65" height="66" viewBox="0 0 65 66" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M32.5 0.5C14.5509 0.5 0 15.0509 0 33C0 50.9502 14.5509 65.5 32.5 65.5C50.4502 65.5 65 50.9502 65 33C65 15.0509 50.4502 0.5 32.5 0.5ZM32.5 61.5015C16.8198 61.5015 4.0625 48.6802 4.0625 32.9999C4.0625 17.3196 16.8198 4.56237 32.5 4.56237C48.1802 4.56237 60.9375 17.3197 60.9375 32.9999C60.9375 48.68 48.1802 61.5015 32.5 61.5015ZM45.4705 21.108L26.4021 40.2962L17.815 31.7091C17.0218 30.9159 15.736 30.9159 14.9418 31.7091C14.1486 32.5023 14.1486 33.7881 14.9418 34.5813L24.9955 44.636C25.7887 45.4282 27.0745 45.4282 27.8687 44.636C27.9601 44.5446 28.0384 44.445 28.1095 44.3415L48.3448 23.9812C49.137 23.188 49.137 21.9022 48.3448 21.108C47.5505 20.3148 46.2648 20.3148 45.4705 21.108Z"
        fill="#00B448"
      />
    </svg>
  );
};

export default Svg;
