import i18next from 'i18next';
import React, { useCallback, useEffect } from 'react';
import { useLocation } from '@reach/router';
import { useMediaQuery } from 'react-responsive';

import { TGuestProps } from '@/layouts/Guest/Guest.types';
import Footer from '@/containers/Footer';
import Header from '@/containers/Header';
import { isZaloApp, scrollToTop } from '@/utils/functions';
import MobileHeader from '@/containers/MobileHeader';
import MobileFooter from '@/containers/MobileFooter';
import { ZALO_MINI_APP_BASE_PATH } from '@/common/constants';
import MiniAppHeader from '@/containers/MiniAppHeader';

const Guest: React.FC<TGuestProps> = ({ children }) => {
  const location = useLocation();
  const pathName = location.pathname;
  const isHome = pathName === `${ZALO_MINI_APP_BASE_PATH}/`;
  const isTablet = useMediaQuery({ maxWidth: 991 });

  const renderHeader = useCallback(() => {
    if (isZaloApp()) {
      return isHome ? <MobileHeader /> : <MiniAppHeader />;
    }
    if (isTablet) {
      return <MobileHeader />;
    }
    return <Header />;
  }, [isHome, isTablet]);

  useEffect(() => {
    scrollToTop();
  }, [location]);

  return (
    <div className="Guest">
      <div className="Guest-header">{renderHeader()}</div>
      <div className="Guest-body">{children}</div>
      <div className="Guest-footer">
        <Footer />
      </div>

      {/* {![Paths.Policy].includes(location.pathname) && <MobileFooter />} */}
      <MobileFooter />
    </div>
  );
};

export default Guest;
