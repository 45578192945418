import i18next from 'i18next';
import React from 'react';

import { EIconColor } from './Icon.enums';
import { TIconProps } from './Icon.types';

const Svg: React.FC<TIconProps> = ({ color = EIconColor.BLACK }) => {
  return (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.3237 12.2107H2.67604C1.61923 12.2107 0.755859 11.3473 0.755859 10.2905V2.64284C0.755859 1.58603 1.61923 0.722656 2.67604 0.722656H10.3237C11.3805 0.722656 12.2439 1.58603 12.2439 2.64284V10.2905C12.2439 11.3521 11.3853 12.2107 10.3237 12.2107Z"
        fill={color}
      />
      <path
        d="M6.50239 9.41737C5.7145 9.41737 4.97379 9.11071 4.41708 8.554C3.86037 7.99729 3.55371 7.25658 3.55371 6.4687C3.55371 5.68081 3.86037 4.9401 4.41708 4.38339C4.97379 3.82668 5.7145 3.52002 6.50239 3.52002C7.29027 3.52002 8.03098 3.82668 8.58769 4.38339C9.1444 4.9401 9.45107 5.68081 9.45107 6.4687C9.45107 7.25658 9.1444 7.99729 8.58769 8.554C8.02627 9.11071 7.29027 9.41737 6.50239 9.41737ZM6.50239 4.1475C5.22384 4.1475 4.18119 5.18543 4.18119 6.4687C4.18119 7.74724 5.21912 8.7899 6.50239 8.7899C7.78093 8.7899 8.82359 7.75196 8.82359 6.4687C8.81887 5.19015 7.78093 4.1475 6.50239 4.1475Z"
        fill={EIconColor.PRIMARY}
      />
      <path
        d="M10.0261 3.42334C10.3388 3.42334 10.5923 3.16986 10.5923 2.85718C10.5923 2.54449 10.3388 2.29102 10.0261 2.29102C9.71344 2.29102 9.45996 2.54449 9.45996 2.85718C9.45996 3.16986 9.71344 3.42334 10.0261 3.42334Z"
        fill={EIconColor.PRIMARY}
      />
    </svg>
  );
};

export default Svg;
