import i18next from 'i18next';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { Progress, ProgressProps, Typography } from 'antd';

import { TLoyaltyProps } from './Loyalty.types.d';
import './Loyalty.scss';
import Button, { EButtonStyleType } from '@/components/Button';
import Icon, { EIconColor, EIconName } from '@/components/Icon';

import IconGift from '@/assets/icons/icon-gift.svg';
import IconClock from '@/assets/icons/icon-clock.svg';
import IconBlock from '@/assets/icons/icon-block.svg';

import { isZaloApp, interpolateColor } from '@/utils/functions';
import { getMyPointAction } from '@/redux/actions';
import Stack from '@/components/Stack';
import Center from '@/components/Center';
import { TRootState } from '@/redux/reducers';

const Loyalty: React.FC<TLoyaltyProps> = ({
  rankName,
  rankIcon,
  rankBG,
  rankPercentage,
  rankLevel,
  rankColor,
  remainingAmountToNextRank = 0,
  onBack,
  discountPercentage,
}) => {
  const dispatch = useDispatch();

  const myPointState = useSelector((state: TRootState) => state.loyaltyReducer.getMyPointResponse)?.data;

  const prefixCls = 'Loyalty';

  const handleBackClick = (): void => {
    onBack?.();
  };

  const getMyPoints = useCallback(() => {
    dispatch(
      getMyPointAction.request({
        params: null,
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    getMyPoints();
  }, [getMyPoints]);

  return (
    <div className={classNames(prefixCls, { 'mini-app': isZaloApp() })}>
      <div className={`${classNames(prefixCls)}-wrapper`}>
        <div
          className={`${classNames(prefixCls)}-header`}
          style={{
            backgroundImage: `url(${isZaloApp() && window.APP_VERSION ? window.APP_VERSION + rankBG : rankBG})`,
          }}
        >
          <div className={`${classNames(prefixCls)}-nav`}>
            {onBack && (
              <span className={`${prefixCls}-nav-back`}>
                <Button
                  className={`${prefixCls}-back-btn`}
                  styleType={EButtonStyleType.TRANSPARENT}
                  iconColor={EIconColor.WHITE}
                  onClick={handleBackClick}
                  iconName={EIconName.FullArrowLeft}
                />
              </span>
            )}

            <div className={`${prefixCls}-nav-title`}>
              <h3>{`Thành Viên ${rankName}`}</h3>
            </div>
          </div>
          <div className={`${classNames(prefixCls)}-rank`}>
            <div className={`${classNames(prefixCls)}-rank-progress`}>
              <Progress
                type="circle"
                percent={rankPercentage}
                strokeWidth={9}
                width={100}
                className={`${classNames(prefixCls)}`}
                format={(): React.ReactElement => (
                  <img className={`${classNames(prefixCls)}-rank-icon`} alt="icnRank" src={rankIcon} />
                )}
                strokeColor={{
                  '0%': rankColor,
                  [`${rankPercentage}%`]: interpolateColor(rankColor, rankPercentage),
                }}
              />
            </div>

            <div className={`${classNames(prefixCls)}-rank-point`}>
              <div className={`${classNames(prefixCls)}-rank-point-label`}>{myPointState?.toLocaleString()}</div>

              <Icon name={EIconName.GuCoinCircle} />
            </div>
          </div>
        </div>
        <div className={`${classNames(prefixCls)}-main`}>
          <Stack space="1rem">
            <Center andText>
              <Typography.Text>
                {i18next.t('Bạn cần chi tiêu {{value}} vnđ nữa để lên hạng!', {
                  value: remainingAmountToNextRank?.toLocaleString(),
                })}
              </Typography.Text>
            </Center>
            <Stack>
              <div
                className={classNames(`${prefixCls}-btn`, 'flex items-center justify-between')}
                onClick={(): void => {
                  console.log(123);
                }}
              >
                <div className={classNames(`${prefixCls}-btn-prefix`, 'flex items-center justify-between')}>
                  <img src={IconGift} alt="IconGift" />
                  <div className={`${classNames(prefixCls)}-btn-name`}>{i18next.t('Đổi ưu đãi')}</div>
                </div>

                <Icon name={EIconName.AngleRight} strokeWidth={3} />
              </div>
              <div
                className={classNames(`${prefixCls}-btn`, 'flex items-center justify-between')}
                onClick={(): void => {
                  console.log(123);
                }}
              >
                <div className={classNames(`${prefixCls}-btn-prefix`, 'flex items-center justify-between')}>
                  <img src={IconClock} alt="IconClock" />
                  <div className={`${classNames(prefixCls)}-btn-name`}>{i18next.t('Chi tiết điểm Gu')}</div>
                </div>

                <Icon name={EIconName.AngleRight} strokeWidth={3} />
              </div>
            </Stack>
            <div className={classNames(`${prefixCls}-rank-chain`)}>
              {Array(6)
                .fill(0)
                .map((_, i) => {
                  if (rankLevel === i + 1) {
                    return (
                      <span>
                        <img src={rankIcon} alt="IconClock" />
                      </span>
                    );
                  }
                  return (
                    <span>
                      <img src={IconBlock} alt="IconClock" />
                    </span>
                  );
                })}
            </div>
            <Stack space="1rem" recursive>
              <Typography.Title level={5} className="capitalize">
                {i18next.t('ưu đãi')}
              </Typography.Title>
              <ul className={`${classNames(prefixCls)}-incentives`}>
                <li className="item">
                  <Typography.Text>
                    {i18next.t('Chiết khấu {{data}}% thanh toán dịch vụ MyGuu', { data: discountPercentage * 100 })}
                  </Typography.Text>
                </li>
                <li className="item">
                  <Typography.Text>{i18next.t('Tích lũy 0,5% Gu trên giá trị dịch vụ MyGuu')}</Typography.Text>
                </li>
                <li className="item">
                  <Typography.Text>{i18next.t('Đổi điểm thành ưu đãi giảm giá khi dùng dịch vụ')}</Typography.Text>
                </li>
                <li className="item">
                  <Typography.Text>{i18next.t('Đổi điểm thành các ưu đãi từ đối tác')}</Typography.Text>
                </li>
              </ul>
            </Stack>
          </Stack>
        </div>
      </div>
    </div>
  );
};

export default Loyalty;
