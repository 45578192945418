import i18next from 'i18next';
import React from 'react';

import { EIconColor } from './Icon.enums';
import { TIconProps } from './Icon.types';

const Svg: React.FC<TIconProps> = ({ color = EIconColor.BLACK }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.33024 2.00012H16.6602C20.0602 2.00012 22.0002 3.92012 22.0002 7.33012V16.6701C22.0002 20.0601 20.0702 22.0001 16.6702 22.0001H7.33024C3.92024 22.0001 2.00024 20.0601 2.00024 16.6701V7.33012C2.00024 3.92012 3.92024 2.00012 7.33024 2.00012ZM12.8202 12.8301H15.6602C16.1202 12.8201 16.4902 12.4501 16.4902 11.9901C16.4902 11.5301 16.1202 11.1601 15.6602 11.1601H12.8202V8.34012C12.8202 7.88012 12.4502 7.51012 11.9902 7.51012C11.5302 7.51012 11.1602 7.88012 11.1602 8.34012V11.1601H8.33024C8.11024 11.1601 7.90024 11.2501 7.74024 11.4001C7.59024 11.5601 7.50024 11.7691 7.50024 11.9901C7.50024 12.4501 7.87024 12.8201 8.33024 12.8301H11.1602V15.6601C11.1602 16.1201 11.5302 16.4901 11.9902 16.4901C12.4502 16.4901 12.8202 16.1201 12.8202 15.6601V12.8301Z"
        fill={color}
      />
    </svg>
  );
};

export default Svg;
