import i18next from 'i18next';
import React from 'react';

import { EIconColor } from './Icon.enums';
import { TIconProps } from './Icon.types';

const Svg: React.FC<TIconProps> = ({ color = EIconColor.BLACK }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_9_13507)">
        <path
          d="M9.99308 11.4306C9.52398 11.9054 9.06427 12.3754 8.59986 12.8407C6.41175 15.0303 4.22286 17.2191 2.03318 19.4071C1.89065 19.5497 1.75673 19.7072 1.59305 19.82C0.987678 20.2399 0.154409 19.8819 0.0189251 19.1564C-0.0476424 18.8023 0.0698297 18.5086 0.310256 18.2571C0.498994 18.0589 0.695564 17.8693 0.889001 17.6758C3.4076 15.158 5.92542 12.6394 8.4448 10.1224C8.48318 10.084 8.52781 10.0511 8.5795 10.0064C8.4542 9.88342 8.34299 9.77531 8.23335 9.66564C5.62156 7.05694 3.01055 4.44668 0.399534 1.8372C0.217844 1.65545 0.0619982 1.46117 0.0157925 1.19639C-0.0789682 0.658195 0.300075 0.114521 0.83888 0.0189469C1.16545 -0.0390241 1.45992 0.0346148 1.69799 0.259448C2.088 0.628426 2.46626 1.00994 2.84608 1.38988C4.0443 2.58612 5.24016 3.78315 6.4376 4.98017C7.61858 6.16074 8.79957 7.34131 9.98055 8.52188C9.9876 8.52894 9.99543 8.53364 10.0111 8.54695C10.0432 8.51718 10.0769 8.4882 10.1074 8.45765C12.8234 5.74084 15.5401 3.02482 18.2553 0.308019C18.6531 -0.0899446 19.2781 -0.101695 19.6877 0.284517C20.08 0.654278 20.1113 1.30684 19.7315 1.70324C19.2358 2.22028 18.7228 2.7193 18.2161 3.22615C15.9865 5.45725 13.7561 7.68757 11.5265 9.91867C11.5038 9.94139 11.4826 9.96568 11.4341 10.0182C11.4771 10.0471 11.5226 10.0683 11.5555 10.102C12.9863 11.5301 14.4155 12.959 15.8455 14.3887C17.0062 15.5489 18.1676 16.7099 19.3282 17.8709C19.4613 18.0041 19.5921 18.1404 19.7205 18.2798C20.015 18.5994 20.0792 19.0381 19.8928 19.4314C19.7221 19.7918 19.329 20.0221 18.9131 19.9986C18.6437 19.9829 18.4237 19.8584 18.2357 19.6703C17.4353 18.8689 16.6342 18.0675 15.8338 17.2669C13.9072 15.3405 11.9807 13.4142 10.0534 11.4878C10.0401 11.4745 10.0252 11.4612 9.99308 11.4314V11.4306Z"
          className="fill"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_9_13507">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Svg;
