import i18next from 'i18next';
import React from 'react';

import Modal from '@/components/Modal';
import Icon, { EIconColor, EIconName } from '@/components/Icon';
import Button, { EButtonStyleType } from '@/components/Button';

import { TModalRequireTurnOnShareLocationMiniAppProps } from './ModalRequireTurnOnShareLocationMiniApp.types';
import './ModalRequireTurnOnShareLocationMiniApp.scss';

const ModalRequireTurnOnShareLocationMiniApp: React.FC<TModalRequireTurnOnShareLocationMiniAppProps> = ({
  visible,
  data,
  onClose,
  onSubmit,
}) => {
  const isUserDeniedGeoLocation = data?.code === 1;

  return (
    <Modal
      centered
      visible={visible}
      className="ModalRequireTurnOnShareLocationMiniApp"
      onClose={onClose}
      hideFooter
      closeable={false}
    >
      <div className="ModalRequireTurnOnShareLocationMiniApp-wrapper">
        <div className="ModalRequireTurnOnShareLocationMiniApp-icon">
          <Icon name={EIconName.LocationFill} color={EIconColor.PRIMARY} />
        </div>
        <div className="ModalRequireTurnOnShareLocationMiniApp-title">{i18next.t('Chia sẻ vị trí')}</div>
        <div className="ModalRequireTurnOnShareLocationMiniApp-description">
          {i18next.t('Bạn hãy chia sẻ vị trí để giúp trải nghiệm tìm kiếm dịch vụ được tốt hơn nhé !')}
        </div>
        <div className="ModalRequireTurnOnShareLocationMiniApp-btn flex justify-center gap-4">
          <Button
            title={i18next.t('Từ chối')}
            size="large"
            styleType={EButtonStyleType.PRIMARY_OUTLINE}
            onClick={onClose}
          />
          <Button title={i18next.t('Đồng ý')} size="large" styleType={EButtonStyleType.PRIMARY} onClick={onSubmit} />
        </div>
      </div>
    </Modal>
  );
};

export default ModalRequireTurnOnShareLocationMiniApp;
