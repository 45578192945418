import { useLocation } from '@reach/router';
import React, { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';

import Footer from '@/containers/Footer';
import Header from '@/containers/Header';
import MobileFooter from '@/containers/MobileFooter';
import MobileHeader from '@/containers/MobileHeader';
import SideBar from '@/containers/SideBar';
import { isZaloApp, scrollToTop } from '@/utils/functions';

import MiniAppHeader from '@/containers/MiniAppHeader';
import './Profile.scss';
import { TProfileProps } from './Profile.types';

const Profile: React.FC<TProfileProps> = ({ children }) => {
  const location = useLocation();
  const isTablet = useMediaQuery({ maxWidth: 991 });

  useEffect(() => {
    scrollToTop();
  }, [location]);

  return (
    <div className="Profile">
      <div className="Profile-header">
        {((): JSX.Element => {
          if (isZaloApp()) {
            return <MiniAppHeader />;
          }
          return isTablet ? <MobileHeader /> : <Header />;
        })()}
      </div>
      <div className="Profile-main">
        <div className="container">
          <div className="Profile-main-wrapper flex">
            {!isTablet && (
              <div className="Profile-sidebar">
                <SideBar />
              </div>
            )}

            <div className="Profile-body">{children}</div>
          </div>
        </div>
      </div>
      <div className="Profile-footer">
        <Footer />
      </div>
      <MobileFooter />
    </div>
  );
};

export default Profile;
