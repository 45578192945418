import i18next from 'i18next';
import React, { useState } from 'react';
import classNames from 'classnames';
import { Col, Form, Row, notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { TChangePasswordProps } from './ChangePassword.types.d';
import './ChangePassword.scss';
import Button, { EButtonStyleType } from '@/components/Button';
import { EIconColor, EIconName } from '@/components/Icon';

import { isZaloApp, showNotification, validationRules } from '@/utils/functions';
import Stack from '@/components/Stack';
import Input from '@/components/Input';
import { TRootState } from '@/redux/reducers';
import { ERole, ETypeNotification } from '@/common/enums';
import { EChangePasswordAction, changePasswordAction } from '@/redux/actions';

const ChangePassword: React.FC<TChangePasswordProps> = ({ onBack, onSuccess }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState<any>({});

  const [api, contextHolder] = notification.useNotification();

  const prefixCls = 'ChangePassword';

  const changePasswordLoading = useSelector(
    (state: TRootState) => state.loadingReducer[EChangePasswordAction.CHANGE_PASSWORD],
  );

  const handleSubmit = (values: any): void => {
    const body = {
      old_password: values?.oldPassword,
      new_password: values?.newPassword,
      role: ERole.USER,
    };

    dispatch(changePasswordAction.request({ body }, handleSubmitSuccess));
  };

  const handleSubmitSuccess = (): void => {
    showNotification(ETypeNotification.SUCCESS, i18next.t('Đổi mật khẩu thành công !'));
    onSuccess?.();
  };

  const handleBackClick = (): void => {
    onBack?.();
  };

  return (
    <div className={classNames(prefixCls, { 'mini-app': isZaloApp() })}>
      <div className={classNames(`${prefixCls}-wrapper`)}>
        {isZaloApp() && (
          <div className={classNames(`${prefixCls}-header`)}>
            <div className={classNames(`${prefixCls}-nav`)}>
              {onBack && (
                <span className={classNames(`${prefixCls}-nav-back`)}>
                  <Button
                    styleType={EButtonStyleType.TRANSPARENT}
                    iconColor={EIconColor.WHITE}
                    onClick={handleBackClick}
                    iconName={EIconName.FullArrowLeft}
                  />
                </span>
              )}

              <div className={classNames(`${prefixCls}-nav-title`)}>
                <h3>Đổi Mật Khẩu</h3>
              </div>
            </div>
          </div>
        )}
        <div className={classNames(`${prefixCls}-main`)}>
          <Form
            form={form}
            layout="vertical"
            onFinish={handleSubmit}
            onValuesChange={(_, values): void => setFormValues({ ...formValues, ...values })}
          >
            <Stack space="2rem">
              <Form.Item
                className={classNames(`${prefixCls}-form-label`)}
                name="oldPassword"
                label={i18next.t('Mật khẩu cũ')}
                rules={[validationRules.required()]}
              >
                <Input size="large" type="password" />
              </Form.Item>
              <Form.Item
                className={classNames(`${prefixCls}-form-label`)}
                name="newPassword"
                label={i18next.t('Mật khẩu mới')}
                rules={[validationRules.required()]}
              >
                <Input size="large" type="password" />
              </Form.Item>
              <Form.Item
                className={classNames(`${prefixCls}-form-label`)}
                name="confirmPassword"
                label={i18next.t('Xác nhận mật khẩu')}
                rules={[validationRules.required(), validationRules.confirmPassword(formValues?.newPassword)]}
              >
                <Input size="large" type="password" />
              </Form.Item>
              <div className={classNames(`${prefixCls}-form-submit`)}>
                <Button
                  title="Lưu"
                  size="large"
                  htmlType="submit"
                  styleType={EButtonStyleType.PRIMARY}
                  disabled={changePasswordLoading}
                />
              </div>
            </Stack>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
