import { createReducer } from 'deox';

import { uiActions } from '@/redux/actions';
import { TService } from '@/common/models';
import Helpers from '@/services/helpers';
import { EPaymentStatus } from '@/common/enums';

type TUIState = {
  geoAppLocation?: {
    latitude: number;
    longitude: number;
  };
  cart?: TService[];
  visibleOtherShopWarning?: boolean;
  pageConfig?: {
    title: string;
    hideBackBtn: boolean;
    backgroundType: string;
  };
  paymentStatus: EPaymentStatus;
};

const initialState: TUIState = {
  geoAppLocation: undefined,
  cart: Helpers.getCart(),
  visibleOtherShopWarning: false,
  pageConfig: {
    title: '',
    hideBackBtn: false,
    backgroundType: 'normal',
  },
  paymentStatus: EPaymentStatus.NONE,
};

const reducer = createReducer(initialState, (handleAction) => [
  handleAction(uiActions.setCart, (state, { payload }) => {
    Helpers.storeCart(payload.data);
    let servicesSameStore: TService[] = [];
    if (Array.isArray(payload.data) && payload.data.length > 0) {
      const lastItem = payload.data[payload.data.length - 1];
      servicesSameStore = payload.data.filter((item) => item.store?.id === lastItem.store?.id);
    }
    return {
      ...state,
      cart: servicesSameStore,
    };
  }),
  handleAction(uiActions.setVisibleOtherShopWarning, (state, { payload }) => {
    return {
      ...state,
      visibleOtherShopWarning: payload?.data,
    };
  }),
  handleAction(uiActions.setGeoLocationApp, (state, { payload }) => ({
    ...state,
    geoAppLocation: {
      latitude: payload.latitude,
      longitude: payload.longitude,
    },
  })),
  handleAction(uiActions.setPageConfig, (state, { payload }) => ({
    ...state,
    pageConfig: {
      title: payload.title,
      hideBackBtn: payload.hideBackBtn,
      backgroundType: payload.backgroundType,
    },
  })),
  handleAction(uiActions.setPaymentStatus, (state, { payload }) => ({
    ...state,
    paymentStatus: payload,
  })),
]);

export default reducer;
