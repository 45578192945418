import { EOrderProcess, EOrderStatus, ERankLevels, ERankNames, EVoucherType } from '@/common/enums';
import { EIconColor } from '@/components/Icon';
import i18n from '@/i18n';

/* eslint-disable no-useless-escape */
export const REGEX = {
  email:
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
  url: /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/i,
  domain: /^[a-zA-Z0-9](?:[a-zA-Z0-9-.]*[a-zA-Z0-9])?$/i,
  alphabetic: /^[a-z\s]+$/i,
  alphanumerial: /^[a-z0-9\s]+$/i,
  numeric: /^\d+$/i,
  onlySpecialKey: /[$&+,:;=?@#|'<>.^*()%`~_!\-"\]\[\\}{'/]/i,
  phoneNumberVietnam: /[\w\W]{1,10}/i,
  password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/i,
};

export const DEFAULT_PAGE = 1;
export const DEFAULT_PAGE_SIZE = 10;

export const dataVoucherTypeOptions = [
  { value: EVoucherType.APP, label: i18n.t('Voucher Sàn') },
  { value: EVoucherType.STORE, label: i18n.t('Voucher Shop') },
  { value: EVoucherType.OTHER, label: i18n.t('Voucher Đổi Thưởng') },
];

export const dataOrderStatusOptions = [
  { value: EOrderStatus.SCHEDULE, label: i18n.t('Lịch Hẹn') },
  { value: EOrderStatus.IN_COMMING, label: i18n.t('Sắp Tới') },
  { value: EOrderStatus.PENDING, label: i18n.t('Đang chờ nhận') },
  { value: EOrderStatus.COMPLETE, label: i18n.t('Đã Hoàn Thành') },
  { value: EOrderStatus.CANCELED, label: i18n.t('Đã Huỷ') },
];

export const dataOrderProcessOptions = [
  {
    value: EOrderProcess.PENDING,
    label: i18n.t('Đang Chờ Nhận'),
    data: { color: EIconColor.PRIMARY },
  },
  {
    value: EOrderProcess.CONFIRMED,
    label: i18n.t('Đã Nhận'),
    data: { color: EIconColor.HAVELOCK_BLUE },
  },
  {
    value: EOrderProcess.COMPLETE,
    label: i18n.t('Hoàn Thành'),
    data: { color: EIconColor.MOUNTAIN_MEADOW },
  },
  {
    value: EOrderProcess.CANCEL,
    label: i18n.t('Đã Huỷ'),
    data: { color: EIconColor.POMEGRANATE },
  },
];

export const ZALO_MINI_APP_BASE_PATH = '/zapps/1057838639345963674';

export const RANK_NAME = [
  { value: ERankLevels.POTENTIAL, name: ERankNames.POTENTIAL },
  { value: ERankLevels.BRONZE, name: ERankNames.BRONZE },
  { value: ERankLevels.SILVER, name: ERankNames.SILVER },
  { value: ERankLevels.GOLD, name: ERankNames.GOLD },
  { value: ERankLevels.PLATINUM, name: ERankNames.PLATINUM },
  { value: ERankLevels.DIAMOND, name: ERankNames.DIAMOND },
];
