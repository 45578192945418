import i18next from 'i18next';
import React from 'react';

import { EIconColor } from './Icon.enums';
import { TIconProps } from './Icon.types';

const Svg: React.FC<TIconProps> = ({ color = EIconColor.BLACK }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.0699 5.23C19.4599 5.07 17.8499 4.95 16.2299 4.86V4.85L16.0099 3.55C15.8599 2.63 15.6399 1.25 13.2999 1.25H10.6799C8.34991 1.25 8.12991 2.57 7.96991 3.54L7.75991 4.82C6.82991 4.88 5.89991 4.94 4.96991 5.03L2.92991 5.23C2.50991 5.27 2.20991 5.64 2.24991 6.05C2.28991 6.46 2.64991 6.76 3.06991 6.72L5.10991 6.52C10.3499 6 15.6299 6.2 20.9299 6.73H21.0099C21.3899 6.73 21.7199 6.44 21.7599 6.05C21.775 5.85024 21.7112 5.65253 21.5822 5.49925C21.4532 5.34596 21.2693 5.24931 21.0699 5.23ZM19.2299 8.14C18.9899 7.89 18.6599 7.75 18.3199 7.75H5.67991C5.33991 7.75 4.99991 7.89 4.76991 8.14C4.53991 8.39 4.40991 8.73 4.42991 9.08L5.04991 19.34C5.15991 20.86 5.29991 22.76 8.78991 22.76H15.2099C18.6999 22.76 18.8399 20.87 18.9499 19.34L19.5699 9.09C19.5899 8.73 19.4599 8.39 19.2299 8.14ZM13.6599 17.75H10.3299C9.91991 17.75 9.57991 17.41 9.57991 17C9.57991 16.59 9.91991 16.25 10.3299 16.25H13.6599C14.0699 16.25 14.4099 16.59 14.4099 17C14.4099 17.41 14.0699 17.75 13.6599 17.75ZM14.4999 13.75H9.49991C9.08991 13.75 8.74991 13.41 8.74991 13C8.74991 12.59 9.08991 12.25 9.49991 12.25H14.4999C14.9099 12.25 15.2499 12.59 15.2499 13C15.2499 13.41 14.9099 13.75 14.4999 13.75Z"
        fill={color}
      />
    </svg>
  );
};

export default Svg;
